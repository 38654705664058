<template>
  <PlantillaShowcase header="Breadcrumb" :codigoHtml="codeHtml" :codigoJs="codeJs">
    <Breadcrumb :home="home" :model="items" />
  </PlantillaShowcase>
</template>

<script>
import PlantillaShowcase from "@/components/PlantillaShowcase";
import Breadcrumb from "primevue/breadcrumb";

export default {
  data() {
    return {
      home: { icon: "pi pi-home", to: "/" },
      items: [{ label: "Computer" }, { label: "Notebook" }, { label: "Accessories" }, { label: "Backpacks" }, { label: "Item" }],
      codeHtml: `<Breadcrumb :home="home" :model="items" />`,
      codeJs: `import Breadcrumb from "primevue/breadcrumb";

export default {
  data() {
    return {
      home: { icon: "pi pi-home", to: "/" },
      items: [{ label: "Computer" }, { label: "Notebook" }, { label: "Accessories" }, { label: "Backpacks" }, { label: "Item" }],
    };
  },
  components: {
    Breadcrumb,
  },
};`,
    };
  },
  components: {
    PlantillaShowcase,
    Breadcrumb,
  },
};
</script>

<style></style>
