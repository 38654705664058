export default {
  mounted() {
    this.menuItems();
  },
  methods: {
    menuItems() {
      document.querySelectorAll(".p-menuitem").forEach((item) => {
        var textElement = item.querySelector(".p-menuitem-text");
        if (textElement.textContent == this.$route.name || (textElement.textContent == "Steps" && ["Step1", "Step2", "Step3", "Step4", "Step5"].includes(this.$route.name))) {
          item.style["pointer-events"] = "none";
        //   item.style.background = "var(--gris-oscuro-500-base)";
          item.style.background = "var(--umu-primario-80)";
        //   textElement.style.color = "white";
          textElement.style.color = "var(--umu-primario-500)";
        } else {
          item.style["pointer-events"] = "all";
          item.style.background = "var(--umu-gris-claro-200)";
          textElement.style.color = "#495057";
        }
      });
    },
  },
};
