<template>
  <div id="fwjsLoader-container">
    <fwjsLoader v-if="loading" :fullScreen="true" :blurry="blurry" :dark="dark" :text1="text1" :text2="text2" />
    <div>
      <div style="height: 56px;">
        <fwjsHeader title="FundeWebJS Showcase" :isLogged="false" style="z-index: 9" :hasBack="true" @backClick="campus" :hasWidget="false" />
      </div>

      <!-- Menu button for mobile -->
      <div class="menu-button-mobile-container">
        <Button label="Menu" @click="toggleMenu" icon="pi pi-bars" class="menu-button-mobile" />
      </div>

      <div class="page-wrapper">
        <div v-if="menuAbierto" class="menu-lateral">
          <Menu :model="rutas" style="width: 100%" />
        </div>
        <div class="contenido">
          <router-view />
          <FdwjsFooter class="footer" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Menu from "primevue/menu";
import Button from "primevue/button";
import fwjsHeader from "@mncs/fwjs-components/fwjsHeader";
import FdwjsFooter from "@/components/footer/FdwjsFooter";
import { mapState } from "vuex";
import fwjsLoader from "@mncs/fwjs-components/fwjsLoader";

export default {
  data() {
    return {
      rutas: [],
      menuAbierto: true,
    };
  },
  computed: {
    ...mapState("loadingGlobal", ["loading", "blurry", "dark", "text1", "text2"]),
  },
  created() {
    this.cargarRutas();
    var width = window.innerWidth > 0 ? window.innerWidth : screen.width;
    if (width >= 786) {
      this.menuAbierto = true;
    } else {
      this.menuAbierto = false;
    }
  },
  mounted() {
    this.menuOnResize();
    this.removeAcceder();
  },
  updated() {
    this.menuOnResize();
  },
  methods: {
    cargarRutas() {
      var routes = this.$router.options.routes;
      routes.forEach((valor, indice, array) => {
        this.rutas.push({
          label: valor.name,
          icon: valor.icon,
          command: () => {
            this.$router.push(valor.path);
          },
        });
      });
    },
    removeAcceder() {
      let acceder = document.querySelector(".fwjsheader__loginlink");
      acceder.parentNode.removeChild(acceder);
    },
    toggleMenu() {
      this.menuAbierto = !this.menuAbierto;
    },
    menuOnResize() {
      window.onresize = () => {
        var width = window.innerWidth > 0 ? window.innerWidth : screen.width;
        if (width >= 786) {
          this.menuAbierto = true;
        } else {
          this.menuAbierto = false;
        }
      };
    },
    campus() {
      window.location.href = "/";
    },
  },
  watch: {
    $route(to, from) {
      var width = window.innerWidth > 0 ? window.innerWidth : screen.width;
      if (width < 786) {
        this.menuAbierto = false;
      } else {
        this.menuAbierto = true;
      }
    },
  },
  components: {
    Menu,
    Button,
    fwjsHeader,
    fwjsLoader,
    FdwjsFooter,
  },
};
</script>

<style>
body {
  margin: 0 !important;
}
</style>

<style scoped>
::v-deep .fwjsheader__title {
  color: #ffffff;
}

.page-wrapper {
  display: flex;
  flex-flow: row;
  width: 100%;
  height: calc(100% - 56px);
}

.menu-lateral {
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 10;
  position: fixed;
  width: 250px;
  height: calc(100% - 56px);
  background-color: var(--umu-gris-claro-200);
}

.contenido {
  overflow: hidden;
  overflow-y: auto;
  padding: 24px 48px 48px 48px;
  flex: 1;
  /* flex: 1;
  display: flex;
  flex-direction: column; */
  margin-left: 250px;
  height: calc(100% - 56px);
}

::v-deep .p-menu {
  border: none;
  background-color: var(--umu-gris-claro-200);
}

::v-deep .p-menu .p-menuitem-link {
  font-family: Inter;
  line-height: 1.5;
  color: var(--umu-gris-oscuro-700);
  font-size: 16px;
  padding: 12px 37px 12px 16px;
  position: relative;
  display: block;
  text-decoration: none;
  height: auto;
}

::v-deep .p-menu .p-menuitem-link:not(.p-disbled):hover {
  background-color: var(--umu-gris-claro-400);
}

::v-deep .p-menu .p-menuitem-link:not(.p-disbled):hover .p-menuitem-text {
  color: #495057 !important;
}

::v-deep .fwjsheader__hamburger {
  background-color: var(--umu-primario-400);
}

.menu-button-mobile-container {
  display: none;
}

@media only screen and (max-width: 786px) {
  .menu-button-mobile-container {
    display: block;
    position: fixed;
    height: 30px;
    width: 100%;
    z-index: 999;
  }

  .menu-lateral {
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 999;
    position: fixed;
    width: 100%;
    height: calc(100% - 56px - 32px);
    bottom: 0;
    background-color: var(--umu-gris-claro-200);
  }

  .contenido {
    overflow: hidden;
    overflow-y: auto;
    /* padding: 40px 40px 0 40px; */
    flex: 1;
    /* flex: 1;
  display: flex;
  flex-direction: column; */
    margin-left: 0;
    height: calc(100% - 56px);
  }
}

.menu-button-mobile {
  height: 100%;
  width: 100%;
  border-radius: 0;
  background: #f2f5f7;
  border-top: 1px solid #e2e4e6;
  border-bottom: 1px solid #e2e4e6;
  border-left: 0;
  border-right: 0;
  color: #495057;
}

.footer {
  margin-top: 30px;
  /* position: absolute;
  bottom: 0;
  height: 200px;
  width: 100%; */
}
</style>
