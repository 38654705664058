<template>
  <div>
    <h1>Menú</h1>
    <p class="fwjs-parrafo">
      Componente <b>fwjsPageWithMenu</b>, contenedor genérico de páginas para FundeWebJS, disponible a partir de la versión 0.1.54. El componente dispone de tres propiedades:
    </p>
    <ul>
      <li><b>title</b>: <i>String</i>. Título que aparecerá en el menú (por usabilidad, no se muestra en las orentaciones <i>top</i> y <i>bottom</i>.</li>
      <li>
        <b>orientation</b>: <i>String</i>. Determina dónde se muestra el menú. Sus posibles valores son: <b><i>left</i></b
        >, <b><i>tom</i></b
        >, <b><i>right</i></b> y <b><i>bottom</i></b
        >. Por defecto: <b><i>left</i></b
        >.
      </li>
      <li>
        <b>fullPage</b>: <i>Boolean</i>. Para que el contenido no esté encorsetado en el centro y pueda ocupar el 100% del espacio disponible. Por defect: <b><i>false</i></b
        >.
      </li>
    </ul>
    <p class="fwjs-parrafo">
      Para utilizarlo, se utilizan dos slots: uno para el promio menú, que puede estar compuesto por diferentes <b>&ltrouter-link&gt</b> para navegación, y otro para el contenido de la página, que puede ser,
      por ejemplo, un <b>&ltrouter-view&gt</b> para mostrar el componente correspondiente a la ruta. Estos dos slots se introudcen en una etiqueta <b>&lttemplate&gt</b> con el atributo <b><i>v-slot: menu</i></b> o
      <b><i>v-slot: content</i></b>, respectivamente.
    </p>
    <p class="fwjs-parrafo">
      Ejemplo de uso:
    </p>
    <div class="bloque-codigo">
      <pre class="codigo prettyprint lang-js">
&ltfwjsPageWithMenu
  v-bind:title="$t('Titulo_del_menu')"
  orientation="left"
  >
  &lttemplate
    v-slot:menu
    >
    &ltrouter-link to="/url_relativa_del_contenido">
      {{ $t("texto_del_enlace") }}
    &lt/router-link>
  &lt/template>
  &lttemplate
    v-slot:content
    >
    &lth1>Contenido&lt/h1>
    &ltp>
      Lorem ipsum dolor sit amet...
    &lt/p>
  &lt/template>
&lt/fwjsPageWithMenu>
      </pre>
    </div>
    <p class="fwjs-parrafo">
      Varias imágenes del resultado final:
    </p>
    <ul>
      <li>
        Ejemplo con <i>orientation="left"</i> y <i>:fullPage="false"</i>:
        <div style="text-align: center">
          <img src="../assets/images/fwjsPageWithMenu_Left_False.png" alt='orientation:"left" :fullPage="false"' style="max-width:80%; margin-top: 16px; border: 1px solid var(--gris-claro-500)" />
        </div>
      </li>
      <li>
        Ejemplo con <i>orientation="top"</i> y <i>:fullPage="false"</i>:
        <div style="text-align: center">
          <img src="../assets/images/fwjsPageWithMenu_Top_False.png" alt='orientation:"top" :fullPage="false"' style="max-width:80%; margin-top: 16px; border: 1px solid var(--gris-claro-500)" />
        </div>
      </li>
      <li>
        Ejemplo con <i>orientation="top"</i> y <i>:fullPage="true"</i>:
        <div style="text-align: center">
          <img src="../assets/images/fwjsPageWithMenu_Top_True.png" alt='orientation:"top" :fullPage="true"' style="max-width:80%; margin-top: 16px; border: 1px solid var(--gris-claro-500)" />
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import Button from "primevue/button";
import PlantillaShowcase from "@/components/PlantillaShowcase";

export default {
  data() {
    return {
      codeHtml: ``,
    };
  },
  mounted() {
    // Code blocks syntax highlight
    let scriptCodePrettify = document.createElement("script");
    scriptCodePrettify.setAttribute("src", "https://cdn.rawgit.com/google/code-prettify/master/loader/run_prettify.js");
    document.head.appendChild(scriptCodePrettify);
  },
  components: {
    Button,
    PlantillaShowcase,
  },
};
</script>
