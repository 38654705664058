import Vue from "vue";
import VueRouter from "vue-router";
// Imports de las vistas
import AccordionView from "@/views/AccordionView.vue";
import AutocompleteView from "@/views/AutocompleteView.vue";
import BreadcrumbView from "@/views/BreadcrumbView.vue";
import ButtonView from "@/views/ButtonView.vue";
import CalendarView from "@/views/CalendarView.vue";
import CardView from "@/views/CardView.vue";
import DatatableView from "@/views/DatatableView.vue";
import DatatableTamanosView from "@/views/DatatableTamanosView.vue";
import DatePickerView from "@/views/DatePickerView.vue";
import DialogView from "@/views/DialogView.vue";
import EnlacesView from "@/views/EnlacesView.vue";
import EspaciadoView from "@/views/EspaciadoView.vue";
import FieldSetView from "@/views/FieldSetView.vue";
import FooterView from "@/views/FooterView.vue";
import HeaderView from "@/views/HeaderView.vue";
import Home from "@/views/Home.vue";
import IconosView from "@/views/IconosView.vue";
import InputGroupView from "@/views/InputGroupView.vue";
import InputTextView from "@/views/InputTextView.vue";
import LoadingView from "@/views/LoadingView.vue";
import MensajesSistemaView from "@/views/MensajesSistemaView.vue";
import MenuView from "@/views/MenuView.vue";
import PaginaErrorView from "@/views/PaginaErrorView.vue";
import PaginationView from "@/views/PaginationView.vue";
import PanelView from "@/views/PanelView.vue";
import ProgressBarView from "@/views/ProgressBarView.vue";
import SelectionView from "@/views/SelectionView.vue";
import StepsView from "@/views/StepsView.vue";
import Step1 from "@/views/Step1.vue";
import Step2 from "@/views/Step2.vue";
import Step3 from "@/views/Step3.vue";
import Step4 from "@/views/Step4.vue";
import Step5 from "@/views/Step5.vue";
import TabsView from "@/views/TabsView.vue";
import TagsView from "@/views/TagsView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    icon: "fad fa-home"
  },
  {
    path: "/accordion",
    name: "Accordion",
    component: AccordionView,
  },
  {
    path: "/autocomplete",
    name: "Autocomplete",
    component: AutocompleteView,
  },
  {
    path: "/breadcrumb",
    name: "Breadcrumb",
    component: BreadcrumbView,
  },
  {
    path: "/button",
    name: "Button",
    component: ButtonView,
  },
  {
    path: "/calendar",
    name: "Calendar",
    component: CalendarView,
  },
  {
    path: "/card",
    name: "Card",
    component: CardView,
  },
  {
    path: "/datatable",
    name: "Datatable",
    component: DatatableView,
  },
  {
    path: "/datatabletamanos",
    name: "Datatable Tamaños",
    component: DatatableTamanosView,
  },
  {
    path: "/datepicker",
    name: "DatePicker",
    component: DatePickerView,
  },
  {
    path: "/dialog",
    name: "Dialog",
    component: DialogView,
  },
  {
    path: "/enlaces",
    name: "Enlaces",
    component: EnlacesView,
  },
  {
    path: "/espaciado",
    name: "Espaciado",
    component: EspaciadoView,
  },
  {
    path: "/fieldset",
    name: "FieldSet",
    component: FieldSetView,
  },
  {
    path: "/footer",
    name: "Footer",
    component: FooterView,
  },
  {
    path: "/header",
    name: "Header",
    component: HeaderView,
  },
  {
    path: "/iconos",
    name: "Iconos",
    component: IconosView,
  },
  {
    path: "/inputgroup",
    name: "InputGroup",
    component: InputGroupView,
  },
  {
    path: "/inputtext",
    name: "InputText",
    component: InputTextView,
  },
  {
    path: "/loading",
    name: "Loading",
    component: LoadingView,
  },
  {
    path: "/mensajes",
    name: "Mensajes",
    component: MensajesSistemaView,
  },
  {
    path: "/menu",
    name: "Menu",
    component: MenuView,
  },
  {
    path: "/paginaerror",
    name: "Página de error",
    component: PaginaErrorView,
  },
  {
    path: "/pagination",
    name: "Pagination",
    component: PaginationView,
  },
  {
    path: "/panel",
    name: "Panel",
    component: PanelView,
  },
  {
    path: "/progressbar",
    name: "ProgressBar",
    component: ProgressBarView,
  },
  {
    path: "/selection",
    name: "Selection",
    component: SelectionView,
  },
  {
    path: "/steps",
    name: "Steps",
    component: StepsView,
    children: [
      { path: "", component: Step1, name: "Step1" },
      { path: "/steps/step2", component: Step2, name: "Step2" },
      { path: "/steps/step3", component: Step3, name: "Step3" },
      { path: "/steps/step4", component: Step4, name: "Step4" },
      { path: "/steps/step5", component: Step5, name: "Step5" },
    ],
  },
  {
    path: "/tabs",
    name: "Tabs",
    component: TabsView,
  },
  {
    path: "/tags",
    name: "Tags",
    component: TagsView,
  },
];

const router = new VueRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes,
});

export default router;
