<template>
  <div>
    <PlantillaShowcase header="Dialog y Confirm Dialog" :codigoHtml="codeHtml" :codigoJs="codeJs">
      <h3>Basic Dialog</h3>
      <Button label="Mostrar" class="p-button-md" @click="openBasic" />
      <Dialog header="Dialog básico" :visible.sync="displayBasic" :modal="true">
        <p>Hola buenos días</p>
      </Dialog>
      <h3>Diálogo de confirmación 1</h3>
      <Button label="Mostrar" class="p-button-md" @click="openConfirm1" />
      <Dialog header="Dialog básico" :visible.sync="displayConfirm1" :modal="true">
        <p>Hola buenas tardes</p>
        <template #footer>
          <Button label="Cancelar" @click="closeBasic" class="p-button-secondary" />
          <Button label="Aceptar" @click="closeBasic" />
        </template>
      </Dialog>
      <h3>Diálogo de confirmación 2</h3>
      <Button label="Mostrar" class="p-button-md" @click="openConfirm2" />
      <Dialog header="Dialog básico" :visible.sync="displayConfirm2" :modal="true">
        <p>Hola buenas noches</p>
        <template #footer>
          <Button icon="fas fa-times" label="Abortar" @click="closeBasic" class="p-button-cancelar" />
          <Button label="Continuar" @click="closeBasic" icon="fas fa-check" class="p-button-aceptar" />
        </template>
      </Dialog>
    </PlantillaShowcase>
  </div>
</template>

<script>
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import PlantillaShowcase from "@/components/PlantillaShowcase";

export default {
  data() {
    return {
      codeHtml: `<h3>Basic Dialog</h3>
<Button label="Mostrar" class="p-button-md" @click="openBasic" />
<Dialog header="Dialog básico" :visible.sync="displayBasic" :modal="true">
  <p>Hola buenos días</p>
</Dialog>
<h3>Diálogo de confirmación 1</h3>
<Button label="Mostrar" class="p-button-md" @click="openConfirm1" />
<Dialog header="Dialog básico" :visible.sync="displayConfirm1" :modal="true">
  <p>Hola buenas tardes</p>
  <template #footer>
    <Button label="Cancelar" @click="closeBasic" class="p-button-secondary" />
    <Button label="Aceptar" @click="closeBasic" />
  </template>
</Dialog>
<h3>Diálogo de confirmación 2</h3>
<Button label="Mostrar" class="p-button-md" @click="openConfirm2" />
<Dialog header="Dialog básico" :visible.sync="displayConfirm2" :modal="true">
  <p>Hola buenas noches</p>
  <template #footer>
    <Button icon="fas fa-times" label="Abortar" @click="closeBasic" class="p-button-cancelar" />
    <Button label="Continuar" @click="closeBasic" icon="fas fa-check" class="p-button-aceptar" />
  </template>
</Dialog>`,
      codeJs: `import Button from "primevue/button";
import Dialog from "primevue/dialog";

export default {
  data() {
    return {
      displayBasic: false,
      displayConfirm1: false,
      displayConfirm2: false,
      refresh: 0,
    };
  },
  methods: {
    actualizar() {
      if (this.refresh == 0) this.refresh++;
      else this.refresh--;
    },
    openBasic() {
      this.displayBasic = true;
      this.actualizar();
    },
    closeBasic() {
      this.displayBasic = false;
      this.actualizar();
    },
    openConfirm1() {
      this.displayConfirm1 = true;
      this.actualizar();
    },
    closeConfirm1() {
      this.displayConfirm1 = false;
      this.actualizar();
    },
    openConfirm2() {
      this.displayConfirm2 = true;
      this.actualizar();
    },
    closeConfirm2() {
      this.displayConfirm2 = false;
      this.actualizar();
    },
  },
  components: {
    Button,
    Dialog,
  },
};
`,
      displayBasic: false,
      displayConfirm1: false,
      displayConfirm2: false,
      refresh: 0,
    };
  },
  methods: {
    actualizar() {
      if (this.refresh == 0) this.refresh++;
      else this.refresh--;
    },
    openBasic() {
      this.displayBasic = true;
      this.actualizar();
    },
    closeBasic() {
      this.displayBasic = false;
      this.actualizar();
    },
    openConfirm1() {
      this.displayConfirm1 = true;
      this.actualizar();
    },
    closeConfirm1() {
      this.displayConfirm1 = false;
      this.actualizar();
    },
    openConfirm2() {
      this.displayConfirm2 = true;
      this.actualizar();
    },
    closeConfirm2() {
      this.displayConfirm2 = false;
      this.actualizar();
    },
  },
  components: {
    Button,
    Dialog,
    PlantillaShowcase,
  },
};
</script>
