<template>
  <div>
    <PlantillaShowcase header="Mensajes del sistema" :codigoHtml="codeHtml" :codigoJs="codeJs">
      <h3>Success</h3>
      <Message severity="success">Área reservada para posibles noticias o alertas para el usuario</Message>
      <InlineMessage severity="success">Área reservada para posibles noticias o alertas para el usuario</InlineMessage>
      <h3>Info</h3>
      <Message severity="info">Área reservada para posibles noticias o alertas para el usuario</Message>
      <InlineMessage severity="info">Área reservada para posibles noticias o alertas para el usuario</InlineMessage>
      <h3>Info Dark</h3>
      Clase "<strong>message-dark</strong>""
      <Message severity="info" class="message-dark">Área reservada para posibles noticias o alertas para el usuario</Message>
      <InlineMessage severity="info" class="message-dark">Área reservada para posibles noticias o alertas para el usuario</InlineMessage>
      <h3>Warn</h3>
      <Message severity="warn">Área reservada para posibles noticias o alertas para el usuario</Message>
      <InlineMessage severity="warn">Área reservada para posibles noticias o alertas para el usuario</InlineMessage>
      <h3>Error</h3>
      <Message severity="error">Área reservada para posibles noticias o alertas para el usuario</Message>
      <InlineMessage severity="error">Área reservada para posibles noticias o alertas para el usuario</InlineMessage>
      <h3>Con botón</h3>
      <Message severity="info" class="message-dark">
        Esto es un mensaje con botón.
        <div>
          <Button label="Botón" class="p-button-sm" style="margin-top: 16px"/>
        </div>
      </Message>
      <h3>Toast</h3>
      <Toast />
      <span> Podemos colocar el componente Toast en App.vue y hacer que se lanzen desde cualquier otro componente.</span>
      <div style="margin-top: 15px">
        <Button label="Info" @click="infoToast" />
        <Button label="Success" @click="successToast" style="margin-left: 15px" />
        <Button label="Warning" @click="warningToast" style="margin-left: 15px" />
        <Button label="Error" @click="errorToast" style="margin-left: 15px" />
      </div>

      <h3>Tooltip</h3>
      <InputText type="text" v-tooltip="'Esto es un tooltip'" />
      <InputText type="text" v-tooltip.right="'Esto es un tooltip'" style="margin-left: 10px" />
      <InputText type="text" v-tooltip.top="'Esto es un tooltip'" style="margin-left: 10px" />
      <InputText type="text" v-tooltip.bottom="'Esto es un tooltip'" style="margin-left: 10px" />
      <InputText type="text" v-tooltip.top="'Esto es un tooltip'" style="margin-left: 10px" />
    </PlantillaShowcase>
  </div>
</template>

<script>
import InlineMessage from "primevue/inlinemessage";
import Message from "primevue/message";
import Button from "primevue/button";
import Tooltip from "primevue/tooltip";
import InputText from "primevue/inputtext";
import PlantillaShowcase from "@/components/PlantillaShowcase";
import Toast from "primevue/toast";

export default {
  methods: {
    infoToast() {
      this.$toast.add({ severity: "info", summary: "Resumen", detail: "Detalle de la tostada", life: 4000 });
    },
    successToast() {
      this.$toast.add({ severity: "success", summary: "Success", detail: "Todo correcto", life: 4000 });
    },
    warningToast() {
      this.$toast.add({ severity: "warn", summary: "Warning", detail: "Algo huele a chamusquina", life: 4000 });
    },
    errorToast() {
      this.$toast.add({ severity: "error", summary: "Error", detail: "Error, abandonen el edificio", life: 4000 });
    },
  },
  data() {
    return {
      codeHtml: `<h3>Success</h3>
<Message severity="success">Área reservada para posibles noticias o alertas para el usuario</Message>
<InlineMessage severity="success">Área reservada para posibles noticias o alertas para el usuario</InlineMessage>
<h3>Info</h3>
<Message severity="info">Área reservada para posibles noticias o alertas para el usuario</Message>
<InlineMessage severity="info">Área reservada para posibles noticias o alertas para el usuario</InlineMessage>
<h3>Info Dark</h3>
Clase "<strong>message-dark</strong>""
<Message severity="info" class="message-dark">Área reservada para posibles noticias o alertas para el usuario</Message>
<InlineMessage severity="info" class="message-dark">Área reservada para posibles noticias o alertas para el usuario</InlineMessage>
<h3>Warn</h3>
<Message severity="warn">Área reservada para posibles noticias o alertas para el usuario</Message>
<InlineMessage severity="warn">Área reservada para posibles noticias o alertas para el usuario</InlineMessage>
<h3>Error</h3>
<Message severity="error">Área reservada para posibles noticias o alertas para el usuario</Message>
<InlineMessage severity="error">Área reservada para posibles noticias o alertas para el usuario</InlineMessage>
<h3>Con botón</h3>
<Message severity="info" class="message-dark">
  Esto es un mensaje con botón.
  <div>
    <Button label="Botón" class="p-button-sm" style="margin-top: 16px"/>
  </div>
</Message>
<h3>Toast</h3>
<Toast />
<span> Podemos colocar el componente Toast en App.vue y hacer que se lanzen desde cualquier otro componente.</span>
<div style="margin-top: 15px">
  <Button label="Info" @click="infoToast" />
  <Button label="Success" @click="successToast" style="margin-left: 15px" />
  <Button label="Warning" @click="warningToast" style="margin-left: 15px" />
  <Button label="Error" @click="errorToast" style="margin-left: 15px" />
</div>
<h3>Tooltip</h3>
<InputText type="text" v-tooltip="'Esto es un tooltip'" />
<InputText type="text" v-tooltip.right="'Esto es un tooltip'" style="margin-left: 10px" />
<InputText type="text" v-tooltip.top="'Esto es un tooltip'" style="margin-left: 10px" />
<InputText type="text" v-tooltip.bottom="'Esto es un tooltip'" style="margin-left: 10px" />
<InputText type="text" v-tooltip.top="'Esto es un tooltip'" style="margin-left: 10px" />`,
      codeJs: `import Message from "primevue/message";
import Button from "primevue/button";
import Tooltip from "primevue/tooltip";
import InputText from "primevue/inputtext";
import Toast from "primevue/toast";

export default {
  methods: {
    infoToast() {
      this.$toast.add({ severity: "info", summary: "Resumen", detail: "Detalle de la tostada", life: 4000 });
    },
    successToast() {
      this.$toast.add({ severity: "success", summary: "Success", detail: "Todo correcto", life: 4000 });
    },
    warningToast() {
      this.$toast.add({ severity: "warn", summary: "Warning", detail: "Algo huele a chamusquina", life: 4000 });
    },
    errorToast() {
      this.$toast.add({ severity: "error", summary: "Error", detail: "Error, abandonen el edificio", life: 4000 });
    },
  },
  components: {
    Message,
    InputText,
    Button,
    Toast,
  },
  directives: {
    tooltip: Tooltip,
  },
};`,
    };
  },
  components: {
    InlineMessage,
    Message,
    InputText,
    PlantillaShowcase,
    Button,
    Toast,
  },
  directives: {
    tooltip: Tooltip,
  },
};
</script>
