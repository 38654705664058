<template>
  <div>
    <PlantillaShowcase header="InputText" :codigoHtml="codeHtml">
      <h3>Básico</h3>
      <InputText class="p-inputtext-lg" placeholder="Grande" />
      <InputText class="p-inputtext-sm" placeholder="Pequeño" style="margin-left: 36px" />
      <InputText placeholder="Normal" style="margin-left: 36px" />

      <h3>Estados</h3>
      <InputText placeholder="Normal" class="p-inputtext-lg" />
      <span class="p-input-icon-right" style="margin-left:36px">
        <i class="fas fa-times p-invalid" />
        <InputText placeholder="Error" class="p-inputtext-lg p-invalid" />
      </span>
      <span class="p-input-icon-right" style="margin-left:36px">
        <i class="fas fa-check p-success" />
        <InputText placeholder="Éxito" class="p-inputtext-lg" />
      </span>

      <h3>Iconos</h3>
      <h4>Izquierda</h4>
      <span class="p-input-icon-left">
        <i class="fas fa-check" />
        <InputText type="text" placeholder="Normal" />
      </span>
      <span class="p-input-icon-left" style="margin-left: 36px">
        <i class="fas fa-check" />
        <InputText type="text" placeholder="Grande" class="p-inputtext-lg" />
      </span>
      <span class="p-input-icon-left" style="margin-left: 36px">
        <i class="fas fa-check" />
        <InputText type="text" placeholder="Pequeño" class="p-inputtext-sm" />
      </span>

      <h4>Derecha</h4>
      <span class="p-input-icon-right">
        <i class="fas fa-times" />
        <InputText type="text" placeholder="Normal" />
      </span>
      <span class="p-input-icon-right" style="margin-left: 36px">
        <i class="fas fa-times" />
        <InputText type="text" placeholder="Grande" class="p-inputtext-lg" />
      </span>
      <span class="p-input-icon-right" style="margin-left: 36px">
        <i class="fas fa-times" />
        <InputText type="text" placeholder="Pequeño" class="p-inputtext-sm" />
      </span>

      <h3>InputText + Botón</h3>
      <h4>Botón izq</h4>
      <span class="p-button-inputtext">
        <Button icon="pi pi-bookmark" class="p-button-izq-inputtext" />
        <InputText class="p-inputtext-button-izq" placeholder="Botón + InputText" />
      </span>
      <span class="p-button-inputtext" style="margin-left: 36px">
        <Button icon="pi pi-bookmark" class="p-button-izq-inputtext-lg" />
        <InputText class="p-inputtext-lg p-inputtext-button-izq" placeholder="Botón + InputText" />
      </span>
      <span class="p-button-inputtext" style="margin-left: 36px">
        <Button icon="pi pi-bookmark" class="p-button-izq-inputtext-sm" />
        <InputText class="p-inputtext-sm p-inputtext-button-izq" placeholder="Botón + InputText" />
      </span>

      <h4>Botón der</h4>
      <span class="p-button-inputtext">
        <InputText class="p-inputtext-button-der" placeholder="InputText + Botón" />
        <Button icon="pi pi-bookmark" class="p-button-der-inputtext" />
      </span>
      <span class="p-button-inputtext" style="margin-left: 36px">
        <InputText class="p-inputtext-lg p-inputtext-button-der" placeholder="InputText + Botón" />
        <Button icon="pi pi-bookmark" class="p-button-der-inputtext-lg" />
      </span>
      <span class="p-button-inputtext" style="margin-left: 36px">
        <InputText class="p-inputtext-sm p-inputtext-button-der" placeholder="InputText + Botón" />
        <Button icon="pi pi-bookmark" class="p-button-der-inputtext-sm" />
      </span>
    </PlantillaShowcase>
  </div>
</template>

<script>
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import PlantillaShowcase from "@/components/PlantillaShowcase";

export default {
  data() {
    return {
      codeHtml: `<h3>Básico</h3>
<InputText class="p-inputtext-lg" placeholder="Grande" />
<InputText class="p-inputtext-sm" placeholder="Pequeño" style="margin-left: 36px" />
<InputText placeholder="Normal" style="margin-left: 36px" />
<h3>Estados</h3>
<InputText placeholder="Normal" class="p-inputtext-lg" />
<span class="p-input-icon-right" style="margin-left:36px">
  <i class="fas fa-times p-invalid" />
  <InputText placeholder="Error" class="p-inputtext-lg p-invalid" />
</span>
<span class="p-input-icon-right" style="margin-left:36px">
  <i class="fas fa-check p-success" />
  <InputText placeholder="Éxito" class="p-inputtext-lg" />
</span>
<h3>Iconos</h3>
<h4>Izquierda</h4>
<span class="p-input-icon-left">
  <i class="fas fa-check" />
  <InputText type="text" placeholder="Normal" />
</span>
<span class="p-input-icon-left" style="margin-left: 36px">
  <i class="fas fa-check" />
  <InputText type="text" placeholder="Grande" class="p-inputtext-lg" />
</span>
<span class="p-input-icon-left" style="margin-left: 36px">
  <i class="fas fa-check" />
  <InputText type="text" placeholder="Pequeño" class="p-inputtext-sm" />
</span>
<h4>Derecha</h4>
<span class="p-input-icon-right">
  <i class="fas fa-times" />
  <InputText type="text" placeholder="Normal" />
</span>
<span class="p-input-icon-right" style="margin-left: 36px">
  <i class="fas fa-times" />
  <InputText type="text" placeholder="Grande" class="p-inputtext-lg" />
</span>
<span class="p-input-icon-right" style="margin-left: 36px">
  <i class="fas fa-times" />
  <InputText type="text" placeholder="Pequeño" class="p-inputtext-sm" />
</span>
<h3>InputText + Botón</h3>
<h4>Botón izq</h4>
<span class="p-button-inputtext">
  <Button icon="pi pi-bookmark" class="p-button-izq-inputtext" />
  <InputText class="p-inputtext-button-izq" placeholder="Botón + InputText" />
</span>
<span class="p-button-inputtext" style="margin-left: 36px">
  <Button icon="pi pi-bookmark" class="p-button-izq-inputtext-lg" />
  <InputText class="p-inputtext-lg p-inputtext-button-izq" placeholder="Botón + InputText" />
</span>
<span class="p-button-inputtext" style="margin-left: 36px">
  <Button icon="pi pi-bookmark" class="p-button-izq-inputtext-sm" />
  <InputText class="p-inputtext-sm p-inputtext-button-izq" placeholder="Botón + InputText" />
</span>
<h4>Botón der</h4>
<span class="p-button-inputtext">
  <InputText class="p-inputtext-button-der" placeholder="InputText + Botón" />
  <Button icon="pi pi-bookmark" class="p-button-der-inputtext" />
</span>
<span class="p-button-inputtext" style="margin-left: 36px">
  <InputText class="p-inputtext-lg p-inputtext-button-der" placeholder="InputText + Botón" />
  <Button icon="pi pi-bookmark" class="p-button-der-inputtext-lg" />
</span>
<span class="p-button-inputtext" style="margin-left: 36px">
  <InputText class="p-inputtext-sm p-inputtext-button-der" placeholder="InputText + Botón" />
  <Button icon="pi pi-bookmark" class="p-button-der-inputtext-sm" />
</span>`,
      codeJs: null,
    };
  },
  components: {
    InputText,
    Button,
    PlantillaShowcase,
  },
};
</script>
