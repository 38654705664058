<template>
  <div>
    <PlantillaShowcase header="Tags" :codigoHtml="codeHtml">
      <Tag severity="info" value="Información"/>
      <br/>
      <Tag severity="success" value="Todo correcto" style="margin-top:10px"/>
      <br/>
      <Tag severity="warning" value="Warning" style="margin-top:10px"/>
      <br/>
      <Tag severity="danger" value="ERROR" style="margin-top:10px"/>
    </PlantillaShowcase>
  </div>
</template>

<script>
import Tag from "primevue/tag";
import PlantillaShowcase from "@/components/PlantillaShowcase";

export default {
  data() {
    return {
      codeHtml: `<Tag severity="info" value="Información"/>
<br/>
<Tag severity="success" value="Todo correcto" style="margin-top:10px"/>
<br/>
<Tag severity="warning" value="Warning" style="margin-top:10px"/>
<br/>
<Tag severity="danger" value="ERROR" style="margin-top:10px"/>`,
      codeJs: null,
    };
  },
  components: {
    Tag,
    PlantillaShowcase,
  },
};
</script>
