<template>
  <div>
    <Card>
      <template #title>
        Step 3
      </template>
      <template #content>
        <span>Contenido del step 3</span>
      </template>
    </Card>
    <div style="display: flex; justify-content: space-between; margin-top: 15px">
      <Button label="Atrás" icon="fas fa-arrow-left" iconPos="left" @click="atras" />
      <Button label="Siguiente" icon="fas fa-arrow-right" iconPos="right" @click="siguiente" />
    </div>
  </div>
</template>

<script>
import Card from "primevue/card";
import Button from "primevue/button";

export default {
  methods: {
    atras() {
      this.$emit("prev-page", { pageIndex: 2 });
    },
    siguiente() {
      this.$emit("next-page", { pageIndex: 2 });
    },
  },
  components: { Card, Button },
};
</script>

<style></style>
