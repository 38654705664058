<template>
  <div>
    <PlantillaShowcase header="Progress Bar" :codigoHtml="codeHtml" :codigoJs="codeJs">
      <h3>Dynamic</h3>
      <h4>Primario</h4>
      <ProgressBar :value="value1" />
      <h4>Info</h4>
      <ProgressBar :value="value1" class="p-progressbar-info" />
      <h3>Static</h3>
      <h4>Primario</h4>
      <ProgressBar :value="value2" :showValue="false" />
      <h4>Info</h4>
      <ProgressBar :value="value2" :showValue="false" class="p-progressbar-info" />
      <h3>Intermediate</h3>
      <h4>Primario</h4>
      <ProgressBar mode="indeterminate" class="p-progressbar-primary" />
      <h4>Info</h4>
      <ProgressBar mode="indeterminate" class="p-progressbar-info" />
    </PlantillaShowcase>
  </div>
</template>

<script>
import ProgressBar from "primevue/progressbar";
import PlantillaShowcase from "@/components/PlantillaShowcase";

export default {
  data() {
    return {
      codeHtml: `<h3>Dynamic</h3>
<h4>Primario</h4>
<ProgressBar :value="value1" />
<h4>Info</h4>
<ProgressBar :value="value1" class="p-progressbar-info" />
<h3>Static</h3>
<h4>Primario</h4>
<ProgressBar :value="value2" :showValue="false" />
<h4>Info</h4>
<ProgressBar :value="value2" :showValue="false" class="p-progressbar-info" />
<h3>Intermediate</h3>
<h4>Primario</h4>
<ProgressBar mode="indeterminate" class="p-progressbar-primary" />
<h4>Info</h4>
<ProgressBar mode="indeterminate" class="p-progressbar-info" />`,
      codeJs: `import ProgressBar from "primevue/progressbar";

export default {
  data() {
    return {
      value1: 0,
      value2: 50,
    };
  },
  interval: null,
  methods: {
    startProgress() {
      this.interval = setInterval(() => {
        let newValue = this.value1 + Math.floor(Math.random() * 10) + 1;
        if (newValue >= 100) {
          newValue = 100;
        }
        this.value1 = newValue;
      }, 2000);
    },
    endProgress() {
      clearInterval(this.interval);
      this.interval = null;
    },
  },
  mounted() {
    this.startProgress();
  },
  beforeDestroy() {
    this.endProgress();
  },
  components: {
    ProgressBar,
  },
};`,
      value1: 0,
      value2: 50,
    };
  },
  interval: null,
  methods: {
    startProgress() {
      this.interval = setInterval(() => {
        let newValue = this.value1 + Math.floor(Math.random() * 10) + 1;
        if (newValue >= 100) {
          newValue = 100;
        }
        this.value1 = newValue;
      }, 2000);
    },
    endProgress() {
      clearInterval(this.interval);
      this.interval = null;
    },
  },
  mounted() {
    this.startProgress();
  },
  beforeDestroy() {
    this.endProgress();
  },
  components: {
    ProgressBar,
    PlantillaShowcase,
  },
};
</script>
