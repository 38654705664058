<template>
  <div>
    <h1 style="margin-bottom: 1.5rem">Loading</h1>
    <Accordion :multiple="true" :activeIndex="[0, 1]">
      <!-- ----------------------------------------------------- PREVIEW ----------------------------------------------------- -->
      <AccordionTab :header="$t('previsualizacion')">
        <div id="fwjsLoader-container">
          <fwjsLoader v-if="loading" :blurry="blurry" :dark="dark" :text1="text ? 'Cargando' : null" :text2="text ? 'Espere un momento' : null" />
          <div>
            <h3 style="padding-top: 0; margin-bottom: 20px;">Opciones:</h3>
            <div class="p-field-checkbox">
              <Checkbox id="fullScreen" v-model="fullScreen" :binary="true" />
              <label for="fullScreen">Pantalla completa</label>
            </div>
            <div class="p-field-checkbox">
              <Checkbox id="dark" v-model="dark" :binary="true" />
              <label for="dark">Modo oscuro</label>
            </div>
            <div class="p-field-checkbox">
              <Checkbox id="blurry" v-model="blurry" :binary="true" />
              <label for="blurry">Fondo difuminado</label>
            </div>
            <div class="p-field-checkbox">
              <Checkbox id="text" v-model="text" :binary="true" />
              <label for="text">Con texto</label>
            </div>
            <div class="p-field-checkbox">
              <InputText id="secs" v-model="secs" type="number" />
              <label for="secs">Duración (en segundos)</label>
            </div>

            <div style="margin-bottom: 20px">
              Pulse para probar el loader:
            </div>
            <Button label="Load" @click="load" />
            <div style="margin-top: 20px">
              Para el cargar a pantalla completa, utilizamos Vuex, y desde App.vue comprobamos el estado de igual manera que en esta pantalla, con v-if.
            </div>
          </div>
        </div>
      </AccordionTab>
      <!-- ----------------------------------------------------- CÓDIGO ----------------------------------------------------- -->
      <AccordionTab header="Documentación" :active="true">
        <span class=".fwjs-parrafo"
          >El loader está incluido en el paquete <strong>@mncs/fwjs-components</strong>, por lo que si hemos seguido las instrucciones iniciales del estilo corporativo ya lo tendremos en las
          dependencias de nuestro proyecto. Por lo tanto, lo único que tendremos que hacer será importarlo donde vayamos a utilizarlo:</span
        >
        <div class="bloque-codigo">
          <pre class="codigo prettyprint lang-js">{{ code0 }}</pre>
        </div>

        <h3>Propiedades del componente</h3>
        <DataTable class="p-datatable-striped datatable-sm .fwjs-parrafo" :value="propsLoader" :autoLayout="true" dataKey="name" :rowHover="false">
          <Column field="name" header="Nombre" headerStyle="text-align: center" bodyStyle="text-align: center"></Column>
          <Column field="type" header="Tipo" headerStyle="text-align: center" bodyStyle="text-align: center"></Column>
          <Column field="default" header="Valor por defecto" headerStyle="text-align: center" bodyStyle="text-align: center"></Column>
          <Column field="description" header="Descripción" headerStyle="text-align: center"></Column>
        </DataTable>

        <h3>Contenedor del loader</h3>
        <span class=".fwjs-parrafo"
          >Para el correcto funcionamiento del loader, es <strong>obligatorio</strong> que añadamos <strong>id="fwjsLoader-container"</strong> al contenedor del mismo, al elemento padre, ya sea a
          pantalla completa, que será el primer div, por ejemplo, o en una sección, como en un componente.</span
        >

        <h3>Loader en el componente</h3>
        <span class=".fwjs-parrafo"
          >Cuando queramos mostrar el loader encima de un componente, o una sección de nuestra página, lo haremos utilizando "<strong>v-if</strong>", pasándole las propiedades correspondientes, que
          normalmente tendremos definidas en el componente. Por ejemplo:</span
        >
        <div class="bloque-codigo">
          <pre class="codigo prettyprint lang-html">{{ code1 }}</pre>
        </div>

        <h3>Loader a pantalla completa</h3>
        <span class=".fwjs-parrafo"
          >Para utilizar el loader a pantalla completa, lo pondremos como primer elemento en nuestro <strong>App.vue</strong>, incluyendo la propiedad <strong>:fullScreen="true"</strong> queramos
          mostrar el loader encima de un componente, o una sección de nuestra página, lo haremos utilizando "<strong>v-if</strong>". Para pasarle el resto de propiedades utilizaremos un
          <strong>módulo de vuex</strong> que incluimos en el paquete npm. Un ejemplo de como quedaría nuestro App.vue sería este:<br /><strong>Template:</strong></span
        >
        <div class="bloque-codigo">
          <pre class="codigo prettyprint lang-html">{{ code2 }}</pre>
        </div>
        <span class=".fwjs-parrafo"><strong>Parte relevante del script:</strong></span>
        <div class="bloque-codigo">
          <pre class="codigo prettyprint lang-js">{{ code3 }}</pre>
        </div>

        <h4>Módulo de vuex</h4>
        <span class=".fwjs-parrafo"
          >Como vemos en el código anterior, utilizamos el módulo <strong>loadingGlobal</strong>, que utilizamos para activar el loader a pantalla completa desde cualquier lugar de la aplicación. En
          primer lugar, debemos <strong>añadirlo a nuestro store</strong>, en src/store/index.js:
        </span>
        <div class="bloque-codigo">
          <pre class="codigo prettyprint lang-js">{{ code4 }}</pre>
        </div>
        Y el código del módulo en cuestión es el siguiente:
        <div class="bloque-codigo">
          <pre class="codigo prettyprint lang-js">{{ code5 }}</pre>
        </div>
        <span class=".fwjs-parrafo">
          Como vemos, mantiene en el estado las variables loading, blurry, dark, text1 y text2, que pasaremos al loader a pantalla completa. Disponemos de <strong>tres actions</strong> para cambiar
          sus valores:</span
        >
        <ul>
          <li>
            <strong>setLoader</strong>: establece las variables del estado (espera un objeto con ellas y sus valores, pueden ser todas o sólo algunas). Lo podemos utilizar tanto para activar el loader
            con los parámetros que queramos como para preparar unos parámetros que se queden fijos para cada vez que se active.
          </li>
          <li><strong>resetLoader</strong>: devuelve todas las variables del estado a sus valores por defecto.</li>
          <li>
            <strong>setLoading</strong>: espera un booleano, y se utiliza sólo para activar o desactivar el loader, es decir, sólo cambia la variable loading. Esta funcionalidad se puede hacer también
            con setLoader, pero esta opción es más intuitiva al tener que pasar directamente un booleano
          </li>
        </ul>
        <span class=".fwjs-parrafo"> Ejemplos de llamadas a estas actions:</span>

        <div class="bloque-codigo">
          <pre class="codigo prettyprint lang-js">{{ code6 }}</pre>
        </div>
      </AccordionTab>
    </Accordion>
  </div>
</template>

<script>
import Button from "primevue/button";
import Checkbox from "primevue/checkbox";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import fwjsLoader from "@mncs/fwjs-components/fwjsLoader";

export default {
  data() {
    return {
      loading: false,
      dark: false,
      fullScreen: false,
      text: false,
      blurry: true,
      secs: 3,
      // Para documentación:
      propsLoader: [
        { name: "fullScreen", type: "Booleano", default: "false", description: "Indica si el loader se mostrará a pantalla completa (true) o sólo en un componente." },
        { name: "blurry", type: "Booleano", default: "true", description: "Difumina el fondo (true) o lo oculta (false)." },
        { name: "dark", type: "Booleano", default: "false", description: "Modo oscuro." },
        { name: "text1", type: "String", default: "null", description: "Texto destacado a mostrar debajo del icono de carga." },
        { name: "text2", type: "String", default: "null", description: "Texto secundario a mostrar debajo del icono de carga." },
      ],
      code0: `import fwjsLoader from "@mncs/fwjs-components/fwjsLoader";
...
...
components: {
  fwjsLoader
}`,
      code1: `<div id="fwjsLoader-container">
  <fwjsLoader v-if="loading" :blurry="blurry" :dark="dark" text1="Cargando" text2="Espere un momento" />
  <div>
    <h3 style="padding-top: 0; margin-bottom: 20px;">Opciones:</h3>
    <div class="p-field-checkbox">
      <Checkbox id="fullScreen" v-model="fullScreen" :binary="true" />
      <label for="fullScreen">Pantalla completa</label>
    </div>
    <div class="p-field-checkbox">
      <Checkbox id="dark" v-model="dark" :binary="true" />
      <label for="dark">Modo oscuro</label>
    </div>
  </div>
</div>`,
      code2: `<template>
  <div id="fwjsLoader-container">
    <fwjsLoader v-if="loading" :fullScreen="true" :blurry="blurry" :dark="dark" :text1="text1" :text2="text2" />
    <div>
      <div style="height: 56px;">
        <fwjsHeader title="FundeWebJS Showcase" :isLogged="false" style="z-index: 9" :hasBack="true" @backClick="campus" :hasWidget="false" />
      </div>

      <!-- Menu button for mobile -->
      <div class="menu-button-mobile-container">
        <Button label="Menu" @click="toggleMenu" icon="pi pi-bars" class="menu-button-mobile" />
      </div>

      <div class="page-wrapper">
        <div v-if="menuAbierto" class="menu-lateral">
          <Menu :model="rutas" style="width: 100%" />
        </div>
        <div class="contenido">
          <router-view />
          <FdwjsFooter class="footer" />
        </div>
      </div>
    </div>
  </div>
</template>`,
      code3: `import { mapState } from "vuex";
import fwjsLoader from "@mncs/fwjs-components/fwjsLoader";

export default {
  computed: {
    ...mapState("loadingGlobal", ["loading", "blurry", "dark", "text1", "text2"]),
  },
  components: {
    fwjsLoader,
  },
};`,
      code4: `import Vue from 'vue'
import Vuex from 'vuex'
import loadingGlobal from "@mncs/fwjs-components/loader/loadingGlobal"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {

  },
  mutations: {

  },
  actions: {

  },
  getters: {

  },
  modules: {
    loadingGlobal
  }
})`,
      code5: `export default {
  namespaced: true,
  state: {
    loading: true,
    blurry: true,
    dark: false,
    text1: null,
    text2: null,
  },
  mutations: {
    SET_LOADER(state, value) {
      state.loading = value.loading;
      state.blurry = value.blurry;
      state.dark = value.dark;
      state.text1 = value.text1;
      state.text2 = value.text2;
    },
    SET_LOADING(state, value) {
      if (typeof value == "boolean") state.loading = value;
    },
  },
  actions: {
    setLoader({ commit }, data) {
      if (!data.hasOwnProperty("blurry")) {
        data.blurry = true;
      }
      if (!data.hasOwnProperty("dark")) {
        data.dark = false;
      }
      if (!data.hasOwnProperty("text1")) {
        data.text1 = null;
      }
      if (!data.hasOwnProperty("text2")) {
        data.text2 = null;
      }
      commit("SET_LOADER", data);
    },
    resetLoader({ commit }) {
      commit("SET_LOADER", { loading: false, blurry: true, dark: false, text1: null, text2: null });
    },
    setLoading({ commit }, value) {
      commit("SET_LOADING", value);
    },
  },
  getters: {},
};`,
      code6: `this.$store.dispatch("loadingGlobal/setLoader", {
  loading: true,
  blurry: this.blurry,
  dark: this.dark,
  text1: this.text ? "Paciencia" : null,
  text2: this.text ? "Esto podría tardar unos minutos" : null,
});
this.$store.dispatch("loadingGlobal/resetLoader");
this.$store.dispatch("loadingGlobal/setLoading", true);`,
    };
  },
  mounted() {
    // Code blocks syntax highlight
    let scriptCodePrettify = document.createElement("script");
    scriptCodePrettify.setAttribute("src", "https://cdn.rawgit.com/google/code-prettify/master/loader/run_prettify.js");
    document.head.appendChild(scriptCodePrettify);
  },
  methods: {
    load() {
      if (this.fullScreen) {
        this.$store.dispatch("loadingGlobal/setLoader", {
          loading: true,
          blurry: this.blurry,
          dark: this.dark,
          text1: this.text ? "Paciencia" : null,
          text2: this.text ? "Esto podría tardar unos minutos" : null,
        });
      } else {
        this.loading = true;
      }
      setTimeout(() => {
        this.loading = false;
        this.$store.dispatch("loadingGlobal/resetLoader");
      }, this.secs * 1000);
    },
  },
  components: {
    Accordion,
    AccordionTab,
    Button,
    Checkbox,
    Column,
    DataTable,
    InputText,
    fwjsLoader,
    // PlantillaShowcase,
  },
};
</script>
