<template>
  <div>
    <PlantillaShowcase header="Autocomplete" :codigoHtml="codeHtml" :codigoJs="codeJs">
      <h3>Normal</h3>
      <AutoComplete v-model="seleccionado" :suggestions="opcionesFiltradas" @complete="search($event)" field="nombre" />
      <h3>Múltiple</h3>
      <AutoComplete :multiple="true" v-model="seleccionados" :suggestions="opcionesFiltradas" @complete="search($event)" field="nombre" />
    </PlantillaShowcase>
  </div>
</template>

<script>
import AutoComplete from "primevue/autocomplete";
import PlantillaShowcase from "@/components/PlantillaShowcase";

export default {
  data() {
    return {
      codeHtml: `<h3>Normal</h3>
<AutoComplete v-model="seleccionado" :suggestions="opcionesFiltradas" @complete="search($event)" field="nombre" />
<h3>Múltiple</h3>
<AutoComplete :multiple="true" v-model="seleccionados" :suggestions="opcionesFiltradas" @complete="search($event)" field="nombre" />`,
      codeJs: `import AutoComplete from "primevue/autocomplete";

export default {
  data() {
    return {
      seleccionado: null,
      seleccionados: null,
      opcionesFiltradas: null,
    };
  },
  methods: {
    search(event) {
      this.opcionesFiltradas = [
        { id: 1, nombre: "Cosa 1" },
        { id: 2, nombre: "Cosa 2" },
        { id: 3, nombre: "Cosa 3" },
      ];
    },
  },
  components: {
    AutoComplete,
  },
};`,
      seleccionado: null,
      seleccionados: null,
      opcionesFiltradas: null,
    };
  },
  methods: {
    search(event) {
      this.opcionesFiltradas = [
        { id: 1, nombre: "Cosa 1" },
        { id: 2, nombre: "Cosa 2" },
        { id: 3, nombre: "Cosa 3" },
      ];
    },
  },
  components: {
    AutoComplete,
    PlantillaShowcase,
  },
};
</script>
