<template>
  <div>
    <Toast />
    <PlantillaShowcase header="Card" :codigoHtml="codeHtml" :codigoJs="codeJs">
      <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-4">
          <Card>
            <template #title>
              Tarjeta Sencilla
            </template>
            <template #content>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore sed consequuntur error repudiandae numquam deserunt quisquam repellat libero asperiores earum nam nobis, culpa ratione
              quam perferendis esse, cupiditate neque quas!
            </template>
          </Card>
        </div>

        <div class="p-col-12 p-md-4">
          <Card>
            <template #header>
              <img alt="user header" src="@/assets/images/imagen-16-9-round-corners.webp" />
            </template>
            <template #title>
              Con imagen y botón
            </template>
            <template #content>
              Lorem ipsum dolor sit...
            </template>
            <template #footer>
              <Button label="Botón" />
            </template>
          </Card>
        </div>

        <div class="p-col-12 p-md-4">
          <Card @click.native="clickCard" class="card-clickable">
            <template #title>
              Tarjeta clickable
            </template>
            <template #content>
              Card con la clase "<strong>card-clickable</strong>"
            </template>
          </Card>
        </div>
      </div>

      <div class="p-grid p-fluid" style="margin-top: 20px">
        <div class="p-col-12 p-md-4">
          <Card @click.native="clickCard" class="card-clickable" style="width:213px">
            <template #header>
              <i class="icon-link blue pi pi-bookmark" />
            </template>
            <template #title>
              Icono azul + ancho fijo +clickable
            </template>
            <template #content> Icono con clases "<strong>icon-link blue pi pi-bookmark</strong>" </template>
          </Card>
        </div>

        <div class="p-col-12 p-md-4">
          <Card @click.native="clickCard" class="card-clickable" style="width:213px">
            <template #header>
              <i class="icon-link green pi pi-chart-line" />
            </template>
            <template #title>
              Icono verde + ancho fijo +clickable
            </template>
            <template #content> Icono con clases "<strong>icon-link green pi pi-chart-line</strong>" </template>
          </Card>
        </div>

        <div class="p-col-12 p-md-4">
          <Card @click.native="clickCard" class="card-clickable" style="width:213px">
            <template #header>
              <i class="icon-link purple pi pi-search" />
            </template>
            <template #title>
              Icono morado + ancho fijo +clickable
            </template>
            <template #content> Icono con clases "<strong>icon-link purple pi pi-search</strong>" </template>
          </Card>
        </div>
      </div>

      <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-4">
          <Card @click.native="clickCard" class="card-clickable" style="width:213px">
            <template #header>
              <i class="icon-link red pi pi-exclamation-triangle" />
            </template>
            <template #title>
              Icono rojo + ancho fijo +clickable
            </template>
            <template #content> Icono con clases "<strong>icon-link red pi pi-exclamation-triangle</strong>" </template>
          </Card>
        </div>

        <div class="p-col-12 p-md-4">
          <Card @click.native="clickCard" class="card-clickable" style="width:213px">
            <template #header>
              <i class="icon-link yellow pi pi-users" />
            </template>
            <template #title>
              Icono amarillo + ancho fijo +clickable
            </template>
            <template #content>
              Icono con clases "<strong>icon-link yellow pi pi-users</strong>"
            </template>
          </Card>
        </div>

        <div class="p-col-12 p-md-4">
          <Card @click.native="clickCard" class="card-clickable" style="width:213px">
            <template #header>
              <i class="icon-link yellow fas fa-times" />
            </template>
            <template #title>
              Icono FontAwesome
            </template>
            <template #content>
              Icono con clases "<strong>icon-link yellow fas fa-times</strong>"
            </template>
          </Card>
        </div>
      </div>
    </PlantillaShowcase>
  </div>
</template>

<script>
import Button from "primevue/button";
import Card from "primevue/card";
import Toast from "primevue/toast";
import PlantillaShowcase from "@/components/PlantillaShowcase";

export default {
  data() {
    return {
      codeHtml: `<div class="p-grid p-fluid">
  <div class="p-col-12 p-md-4">
    <Card>
      <template #title>
        Tarjeta Sencilla
      </template>
      <template #content>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore sed consequuntur error repudiandae numquam deserunt quisquam repellat libero asperiores earum nam nobis, culpa ratione
        quam perferendis esse, cupiditate neque quas!
      </template>
    </Card>
  </div>
  <div class="p-col-12 p-md-4">
    <Card>
      <template #header>
        <img alt="user header" src="@/assets/images/imagen-16-9-round-corners.webp" />
      </template>
      <template #title>
        Con imagen y botón
      </template>
      <template #content>
        Lorem ipsum dolor sit...
      </template>
      <template #footer>
        <Button label="Botón" />
      </template>
    </Card>
  </div>
  <div class="p-col-12 p-md-4">
    <Card @click.native="clickCard" class="card-clickable">
      <template #title>
        Tarjeta clickable
      </template>
      <template #content>
        Card con la clase "<strong>card-clickable</strong>"
      </template>
    </Card>
  </div>
</div>
<div class="p-grid p-fluid" style="margin-top: 20px">
  <div class="p-col-12 p-md-4">
    <Card @click.native="clickCard" class="card-clickable" style="width:213px">
      <template #header>
        <i class="icon-link blue pi pi-bookmark" />
      </template>
      <template #title>
        Icono azul + ancho fijo +clickable
      </template>
      <template #content> Icono con clases "<strong>icon-link blue pi pi-bookmark</strong>" </template>
    </Card>
  </div>
  <div class="p-col-12 p-md-4">
    <Card @click.native="clickCard" class="card-clickable" style="width:213px">
      <template #header>
        <i class="icon-link green pi pi-chart-line" />
      </template>
      <template #title>
        Icono verde + ancho fijo +clickable
      </template>
      <template #content> Icono con clases "<strong>icon-link green pi pi-chart-line</strong>" </template>
    </Card>
  </div>
  <div class="p-col-12 p-md-4">
    <Card @click.native="clickCard" class="card-clickable" style="width:213px">
      <template #header>
        <i class="icon-link purple pi pi-search" />
      </template>
      <template #title>
        Icono morado + ancho fijo +clickable
      </template>
      <template #content> Icono con clases "<strong>icon-link purple pi pi-search</strong>" </template>
    </Card>
  </div>
</div>
<div class="p-grid p-fluid">
  <div class="p-col-12 p-md-4">
    <Card @click.native="clickCard" class="card-clickable" style="width:213px">
      <template #header>
        <i class="icon-link red pi pi-exclamation-triangle" />
      </template>
      <template #title>
        Icono rojo + ancho fijo +clickable
      </template>
      <template #content> Icono con clases "<strong>icon-link red pi pi-exclamation-triangle</strong>" </template>
    </Card>
  </div>
  <div class="p-col-12 p-md-4">
    <Card @click.native="clickCard" class="card-clickable" style="width:213px">
      <template #header>
        <i class="icon-link yellow pi pi-users" />
      </template>
      <template #title>
        Icono amarillo + ancho fijo +clickable
      </template>
      <template #content>
        Icono con clases "<strong>icon-link yellow pi pi-users</strong>"
      </template>
    </Card>
  </div>
  <div class="p-col-12 p-md-4">
    <Card @click.native="clickCard" class="card-clickable" style="width:213px">
      <template #header>
        <i class="icon-link yellow fas fa-times" />
      </template>
      <template #title>
        Icono FontAwesome
      </template>
      <template #content>
        Icono con clases "<strong>icon-link yellow fas fa-times</strong>"
      </template>
    </Card>
  </div>
</div>`,
      codeJs: `import Button from "primevue/button";
import Card from "primevue/card";
import Toast from "primevue/toast";

export default {
  methods: {
    clickCard() {
      this.$toast.add({ severity: "info", summary: "Click", detail: "Has hecho click en una tarjeta", life: 3000 });
    },
  },
  components: {
    Button,
    Card,
    Toast,
  },
};`,
    };
  },
  methods: {
    clickCard() {
      this.$toast.add({ severity: "info", summary: "Click", detail: "Has hecho click en una tarjeta", life: 3000 });
    },
  },
  components: {
    Button,
    Card,
    Toast,
    PlantillaShowcase,
  },
};
</script>
