<template>
  <div style="text-align: left">
    <h1 style="text-align: left">
      Showcase FundeWebJS
    </h1>
    <h2 class="umu-desc">Casos de uso de componentes Primevue con el estilo corporativo de la UMU</h2>
    <p style="text-align: justify">Seleccione un componente del menú lateral para verlo con el estilo corporativo aplicado, y el código correspondiente.</p>
    <h3>Cómo importar el estilo corporativo</h3>
    <p style="text-align: justify">
      El estilo corporativo está subido al repositorio Verdaccio, así que en primer lugar, debemos tener en la raíz de nuestro proyecto el archivo <b>.npmrc</b> con la siguiente línea:
    </p>
    <div class="bloque-codigo">
      <pre class="codigo prettyprint lang-js">registry = https://verdaccio.um.es</pre>
    </div>
    <p style="text-align: justify">
      Después, tendremos que bajarnos el paquete poniendo el siguiente comando en la terminal, y también los paquetes de primevue (con primeicons y primeflex) y los iconos de FontAwesome:
    </p>
    <div class="bloque-codigo">
      <pre class="codigo prettyprint lang-js">
npm i --save @mncs/fwjs-components
npm i --save primeflex@2.0.0
npm i --save primeicon@4.1.0
npm i --save primevue@2.6.0
npm i --save v-calendar@2.3.2
npm i --save vue-auth-image@0.0.3
npm i --save @fortawesome/fontawesome-free</pre
      >
    </div>
    <p style="text-align: justify">Una vez los hecho esto sólo tendremos que importarlos en nuestro <b>main.js</b> (después de haber importado los de Primevue y FontAwesome), añadiendo:</p>
    <div class="bloque-codigo">
      <pre class="codigo prettyprint lang-js">
// Primevue
import PrimeVue from "primevue/config";

Vue.use(PrimeVue);

// Estilos Primevue
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";

// Font Awesome
import "@fortawesome/fontawesome-free/css/all.css";
const imported = document.createElement("link");
imported.href =
  process.env.NODE_ENV !== "production" ? "https://recursosdesa.um.es/forja/mncs/fontawesome/fa-5-pro/css/all.min.css" : "https://recursos.um.es/forja/mncs/fontawesome/fa-5-pro/css/all.min.css";
imported.rel = "stylesheet";
imported.type = "text/css";
document.head.appendChild(imported);

import ToastService from "primevue/toastservice";
Vue.use(ToastService);

// Estilo corporativo
import "@mncs/fwjs-components/estiloUMU.css";

import mixinFixEstiloUMU from "@mncs/fwjs-components/estiloUMU/mixinFixEstiloUMU.js";
Vue.mixin(mixinFixEstiloUMU);

import VueAuthImage from 'vue-auth-image';
Vue.use(VueAuthImage);</pre
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      codigoImport: `import "./assets/estiloUMU/fdwjs.css";`,
    };
  },
  mounted() {
    // Code blocks syntax highlight
    let scriptCodePrettify = document.createElement("script");
    scriptCodePrettify.setAttribute("src", "https://cdn.rawgit.com/google/code-prettify/master/loader/run_prettify.js");
    document.head.appendChild(scriptCodePrettify);
  },
};
</script>

<style>
.bloque-codigo {
  background: #ffffff;
  overflow: auto;
  width: auto;
  border: solid #bec7cf;
  border-width: 0.1em 0.1em 0.1em 0.8em;
  padding: 0.2em 0.6em;
  margin: 0.75rem 0;
}

.codigo {
  border: none !important;
}

.umu-desc {
  font-size: var(--big-size-3);
  line-height: 1.36;
  font-weight: 300;
}
</style>
