<template>
  <footer class="fwjsfooter">
    <span class="fwjsfooter__title">Universidad de Murcia - ATICA</span>
    <ul class="fwjsfooter__list">
      <li class="fwjsfooter__listitem">
        <router-link
          to="/accesibilidad"
          class="fwjsfooter_listlink"
        >
          Accesibilidad
        </router-link>
      </li>
      <li class="fwjsfooter__listitem">
        <router-link
          to="/navegacion"
          class="fwjsfooter_listlink"
        >
          Recomendaciones de navegación
        </router-link>
      </li>
      <li class="fwjsfooter__listitem">
        <a
          href="http://dj.um.es/"
          target="_blank"
          class="fwjsfooter_listlink"
        >Incidencias</a>
      </li>
    </ul>
  </footer>
</template>

<i18n>
{
  "en": {
    "accesibility": "Accesibility",
    "browse": "Navigation preferences",
    "incidents": "Incidents"
  },
  "es": {
    "accesibility": "Accesibilidad",
    "browse": "Recomendaciones de navegación",
    "incidents": "Incidencias"
  }
}
</i18n>

<script>
export default {
  name: 'FwjsFooter',
};
</script>

<style lang="scss" scoped>
@import './_vars.scss';

.fwjsfooter {
  color: #555;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 0.75rem;
  padding: 1.5rem 1.5rem;
  @media (max-width: $screen-sm-min) {
    border-top: 2px solid #d8dde1;
    flex-direction: column-reverse;
  }
  &__list {
    list-style: none;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
  }
  &__listitem {
    display: inline-block;
    padding: 0 1rem;
    border-right: 1px solid #555;
    @media (max-width: $screen-sm-min) {
      border-right: 0px none;
      display: block;
      padding: 0.5rem 0;
    }
    &:last-child {
      padding: 0 0 0 1rem;
      border-right: 0px none;
      @media (max-width: $screen-sm-min) {
        padding: 0.5rem 0;
        margin: 0 0 1rem 0;
      }
    }
  }
  &_listlink {
    color: #555;
    &:hover {
      color: var(--link-color);
    }
  }
}
</style>
