<template>
  <div>
    <h1>Footer</h1>
    <p class="fwjs-parrafo">
      Disponemos de un footer simple en los componentes de @mncs/fwjs-components, a partir de la versión 0.1.50, que se verá así:
    </p>
    <div style="text-align: center; border: solid 1px #dee2e6">
      <img src="../assets/images/footer.png" alt="Icono Font Awesome 5" style="max-width:80%" />
    </div>
    <p class="fwjs-parrafo">Podemos incluirlo en nuestro <strong>App.vue</strong> de la siguiente manera (ejemplo completo de App.vue):</p>
    <div class="bloque-codigo">
      <pre class="codigo prettyprint lang-js">
&lttemplate>
  &ltdiv id="app fwjsLoader-container">
    &ltfwjsLoader v-if="loading" :fullScreen="true" :blurry="blurry" :dark="dark" :text1="text1" :text2="text2" />
    &ltToast />
    &ltfwjsSessionModal v-if="isLogged" @expired="logout" style="width: 100%" />
    &ltdiv style="height: 56px">
      &ltfwjsHeader :title="$t('titulo')" :has-back="true" :is-logged="isLogged" style="z-index: 9" />
    &lt/div>
    &ltdiv id="contenido">
      &ltrouter-view id="vista"/>
      &ltfwjsFooter />
    &lt/div>
  &lt/div>
&lt/template>

&ltscript>
import fwjsHeader from "@mncs/fwjs-components/fwjsHeader";
import AuthRefresher from "@/mixins/AuthRefresher";
import { mapGetters, mapState } from "vuex";
import Toast from "primevue/toast";
import fwjsSessionModal from "@mncs/fwjs-components/fwjsSessionModal";
import fwjsLoader from "@mncs/fwjs-components/fwjsLoader";
import fwjsFooter from "@mncs/fwjs-components/fwjsFooter";

export default {
  computed: {
    ...mapState("loadingGlobal", ["loading", "blurry", "dark", "text1", "text2"]),
    ...mapGetters("login", ["isLogged"]),
  },
  methods: {
    logout() {
      if (this.isLogged) {
        this.$store.dispatch("login/revokeToken");
      }
    },
  },
  mixins: [AuthRefresher],
  components: {
    fwjsHeader,
    Toast,
    fwjsSessionModal,
    fwjsLoader,
    fwjsFooter
  },
  watch: {
    isLogged: function(newValue) {
      this.$store.dispatch("loadingGlobal/setLoading", !newValue);
    },
  },
};
&lt/script>

&ltstyle scoped>
#contenido {
  overflow: hidden;
  overflow-y: auto;
  padding: 24px 48px 0 48px;
  flex: 1;
}

#vista {
  min-height: calc(100vh - 174px);
}
&lt/style>
</pre
      >
    </div>
    <p class="fwjs-parrafo">
      Como vemos, sólo tenemos que importarlo y ponerlo en el template. Aplicamos estilos al id #vista para que se quede al final de la pantalla aunque el contenido de la página no ocupe todo el alto.
    </p>
    <p class="fwjs-parrafo">
      Requisito:
    </p>
    <p class="fwjs-parrafo">
      Es necesario tener la declaración de accesibilidad de la aplicación, pues el enlace "Accesibilidad" navega a la <strong>ruta /accesibilidad</strong> que tendremos que tener. Podemos ver cómo
      añadirla en Confluence: <a href="https://confluence.um.es/confluence/pages/viewpage.action?pageId=209354808"><strong>Declaración de accesibilidad y mantenimiento</strong></a
      >.
    </p>
  </div>
</template>

<script>
import Button from "primevue/button";
import PlantillaShowcase from "@/components/PlantillaShowcase";

export default {
  data() {
    return {
      codeHtml: ``,
    };
  },
  mounted() {
    // Code blocks syntax highlight
    let scriptCodePrettify = document.createElement("script");
    scriptCodePrettify.setAttribute("src", "https://cdn.rawgit.com/google/code-prettify/master/loader/run_prettify.js");
    document.head.appendChild(scriptCodePrettify);
  },
  components: {
    Button,
    PlantillaShowcase,
  },
};
</script>
