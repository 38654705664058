<template>
  <div>
    <h1 style="margin-bottom: 1.5rem">Página de error</h1>
    <Accordion :multiple="true" :activeIndex="[0, 1]">
      <!-- ----------------------------------------------------- PREVIEW ----------------------------------------------------- -->
      <AccordionTab :header="$t('previsualizacion')">
        La página de error (componente <strong>fwjsError</strong>) se vería así:
        <div style="text-align: center">
          <img src="../assets/images/paginaError.png" alt="Icono Font Awesome 5" style="max-width:80%; margin-top: 16px; border: 1px solid var(--gris-claro-500)" />
        </div>
      </AccordionTab>
      <!-- ----------------------------------------------------- CÓDIGO ----------------------------------------------------- -->
      <AccordionTab header="Documentación" :active="true">
        <h3>fwjsError</h3>

        <span class=".fwjs-parrafo"
          >El componente <strong>fwjsError</strong> está incluido en el paquete <strong>@mncs/fwjs-components</strong>, por lo que si hemos seguido las instrucciones iniciales del estilo corporativo
          ya lo tendremos en las dependencias de nuestro proyecto. Por lo tanto, lo único que tendremos que hacer será importarlo y usarlo como cualquier otro componente.</span
        >
        <div class="bloque-codigo">
          <pre class="codigo prettyprint lang-js">{{ code0 }}</pre>
        </div>
        <span class=".fwjs-parrafo">El componente ofrece <strong>props</strong> y <strong>slots</strong> para personalizar el icono y el texto.</span>
        <!-- SEGUIR POR AQUÍ -->
        <h3>Propiedades del componente</h3>
        <DataTable class="p-datatable-striped datatable-sm .fwjs-parrafo" :value="propsError" :autoLayout="true" dataKey="name" :rowHover="false">
          <Column field="name" header="Nombre" headerStyle="text-align: center" bodyStyle="text-align: center"></Column>
          <Column field="type" header="Tipo" headerStyle="text-align: center" bodyStyle="text-align: center"></Column>
          <Column field="default" header="Valor por defecto" headerStyle="text-align: center" bodyStyle="text-align: center"></Column>
          <Column field="description" header="Descripción" headerStyle="text-align: center"></Column>
        </DataTable>

        <h3>Slots</h3>
        <span class=".fwjs-parrafo"
          >El componente ofrece dos slots para personalizar el título y el texto, que se haría, respectivamente, dentro de los bloques <strong>&lttemplate #titulo&gt</strong> y
          <strong>&lttemplate #texto&gt</strong>.</span
        >
      </AccordionTab>
    </Accordion>
  </div>
</template>

<script>
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";

export default {
  data() {
    return {
      propsError: [
        { name: "icono", type: "String", default: "fad fa-exclamation-triangle", description: "Icono en el título." },
        { name: "titulo", type: "String", default: "algo ha fallado", description: "Título, texto resaltado." },
        { name: "texto", type: "String", default: "null", description: "Texto normal bajo el título. Por defecto es null pero muestra un texto con un enlace." },
      ],
      code0: `<div v-if="!showError">
  ...
</div>
<fwjsError v-else/>

...

import fwjsError from "@mncs/fwjs-components/fwjsError";
...
...
components: {
  fwjsError
}`,
    };
  },
  mounted() {
    // Code blocks syntax highlight
    let scriptCodePrettify = document.createElement("script");
    scriptCodePrettify.setAttribute("src", "https://cdn.rawgit.com/google/code-prettify/master/loader/run_prettify.js");
    document.head.appendChild(scriptCodePrettify);
  },
  components: {
    Accordion,
    AccordionTab,
    Column,
    DataTable,
  },
};
</script>
