<template>
  <div>
    <h1>{{ header }}</h1>
    <Accordion :multiple="true" :activeIndex="[0, 1]">
      <!-- ----------------------------------------------------- PREVIEW ----------------------------------------------------- -->
      <AccordionTab :header="$t('previsualizacion')">
        <slot></slot>
      </AccordionTab>
      <!-- ----------------------------------------------------- CÓDIGO ----------------------------------------------------- -->
      <AccordionTab :header="$t('codigo')" :active="true">
        <!-- Código html -->
        <div v-if="codigoHtml">
          <h3>Template</h3>
          <div class="bloque-codigo">
            <pre class="codigo prettyprint lang-html">{{ codigoHtml }}</pre>
          </div>
        </div>
        <div v-if="codigoJs">
          <h3>Script</h3>
          <!-- Código javascript -->
          <div class="bloque-codigo">
            <pre class="codigo prettyprint lang-js">{{ codigoJs }}</pre>
          </div>
        </div>
      </AccordionTab>
    </Accordion>
  </div>
</template>

<script>
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";

export default {
  props: ["codigoHtml", "codigoJs", "header"],
  mounted() {
    // Code blocks syntax highlight
    let scriptCodePrettify = document.createElement("script");
    scriptCodePrettify.setAttribute("src", "https://cdn.rawgit.com/google/code-prettify/master/loader/run_prettify.js");
    document.head.appendChild(scriptCodePrettify);
  },
  components: {
    Accordion,
    AccordionTab,
  },
};
</script>

<style scoped>
.bloque-codigo {
  background: #ffffff;
  overflow: auto;
  width: auto;
  border: solid #bec7cf;
  border-width: 0.1em 0.1em 0.1em 0.8em;
  padding: 0.2em 0.6em;
  margin: 0.75rem 0;
}

.codigo {
  border: none !important;
}
</style>
