<template>
  <div>
    <PlantillaShowcase header="Accordion" :codigoHtml="codeHtml">
      <Accordion :multiple="true" :activeIndex="[0]">
        <AccordionTab header="Did you know that...">
          Francis Ford Coppola's legendary continuation and sequel to his landmark 1972 film, The_Godfather, parallels the young Vito Corleone's rise with his son Michael's spiritual fall, deepening
          The_Godfather's depiction of the dark side of the American dream. In the early 1900s, the child Vito flees his Sicilian village for America after the local Mafia kills his family. Vito
          struggles to make a living, legally or illegally.
        </AccordionTab>
        <AccordionTab header="Did you know that...">
          Content
        </AccordionTab>
        <AccordionTab header="Did you know that...">
          Content
        </AccordionTab>
      </Accordion>
    </PlantillaShowcase>
  </div>
</template>

<script>
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import PlantillaShowcase from "@/components/PlantillaShowcase";

export default {
  data() {
    return {
      codeHtml: `<Accordion :multiple="true" :activeIndex="[0]">
  <AccordionTab header="Did you know that..." >
    Francis Ford Coppola's legendary continuation and sequel to his landmark 1972 film, The_Godfather, parallels the young Vito Corleone's rise with his son Michael's spiritual fall, deepening The_Godfather's depiction of the dark side of the American dream. In the early 1900s, the child Vito flees his Sicilian village for America after the local Mafia kills his family. Vito struggles to make a living, legally or illegally.
  </AccordionTab>
  <AccordionTab header="Did you know that...">
    Content
  </AccordionTab>
  <AccordionTab header="Did you know that...">
    Content
  </AccordionTab>
</Accordion>`,
      codeJs: `export default {}`,
    };
  },
  components: {
    Accordion,
    AccordionTab,
    PlantillaShowcase,
  },
};
</script>
