<template>
  <div>
    <PlantillaShowcase header="Botones" :codigoHtml="codeHtml">
      <h3>Botón primario</h3>
      <div class=".fwjs-parrafo">
        <Button label="Botón" />
        <Button label="Grande" class="p-button-lg" style="margin-left: 20px" />
        <Button label="Mediano" class="p-button-md" style="margin-left: 20px" />
        <Button label="Pequeño" class="p-button-sm" style="margin-left: 20px" />
      </div>
      <h3>Botón secundario</h3>
      <div class=".fwjs-parrafo">
        <Button label="Botón" class="p-button-secondary" />
        <Button label="Grande" class="p-button-lg p-button-secondary" style="margin-left: 20px" />
        <Button label="Mediano" class="p-button-md p-button-secondary" style="margin-left: 20px" />
        <Button label="Pequeño" class="p-button-sm p-button-secondary" style="margin-left: 20px" />
      </div>
      <h3>Botón terciario</h3>
      <div class=".fwjs-parrafo">
        <Button label="Botón" class="p-button-terciary" />
        <Button label="Grande" class="p-button-lg p-button-terciary" style="margin-left: 20px" />
        <Button label="Mediano" class="p-button-md p-button-terciary" style="margin-left: 20px" />
        <Button label="Pequeño" class="p-button-sm p-button-terciary" style="margin-left: 20px" />
      </div>
      <h3>Iconos</h3>
      <div class=".fwjs-parrafo">
        <Button icon="fas fa-star" />
        <Button icon="fas fa-check" label="Mu bien" class="p-button-secondary" style="margin-left: 20px" />
        <Button icon="fas fa-times" label="Mu mal" class="p-button-sm p-button-terciary" style="margin-left: 20px" />
      </div>
      <h3>Botones con color (Otro nivel de información)</h3>
      <div class=".fwjs-parrafo">
        <Button icon="fas fa-times" label="Cancelar" class="p-button-cancelar" />
        <Button icon="fas fa-check" label="Aceptar" class="p-button-aceptar" style="margin-left: 20px" />
      </div>
      <h3>Botones terciarios agrupados</h3>
      <div class=".fwjs-parrafo">
        <span class="p-buttonset" style="height: 48px">
          <Button label="Acción 1" icon="fa fa-check" />
          <Button label="Acción 2" icon="pi pi-trash" />
          <Button label="Acción 2" icon="pi pi-trash" />
          <Button label="Acción 2" icon="pi pi-trash" />
          <Button label="Acción 3" icon="fa fa-times" />
        </span>
      </div>
    </PlantillaShowcase>
  </div>
</template>

<script>
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import PlantillaShowcase from "@/components/PlantillaShowcase";

export default {
  data() {
    return {
      codeHtml: `<h3>Botón primario</h3>
<div class=".fwjs-parrafo">
  <Button label="Botón" />
  <Button label="Grande" class="p-button-lg" style="margin-left: 20px" />
  <Button label="Mediano" class="p-button-md" style="margin-left: 20px" />
  <Button label="Pequeño" class="p-button-sm" style="margin-left: 20px" />
</div>
<h3>Botón secundario</h3>
<div class=".fwjs-parrafo">
  <Button label="Botón" class="p-button-secondary" />
  <Button label="Grande" class="p-button-lg p-button-secondary" style="margin-left: 20px" />
  <Button label="Mediano" class="p-button-md p-button-secondary" style="margin-left: 20px" />
  <Button label="Pequeño" class="p-button-sm p-button-secondary" style="margin-left: 20px" />
</div>
<h3>Botón terciario</h3>
<div class=".fwjs-parrafo">
  <Button label="Botón" class="p-button-terciary" />
  <Button label="Grande" class="p-button-lg p-button-terciary" style="margin-left: 20px" />
  <Button label="Mediano" class="p-button-md p-button-terciary" style="margin-left: 20px" />
  <Button label="Pequeño" class="p-button-sm p-button-terciary" style="margin-left: 20px" />
</div>
<h3>Iconos</h3>
<div class=".fwjs-parrafo">
  <Button icon="fas fa-star" />
  <Button icon="fas fa-check" label="Mu bien" class="p-button-secondary" style="margin-left: 20px" />
  <Button icon="fas fa-times" label="Mu mal" class="p-button-sm p-button-terciary" style="margin-left: 20px" />
</div>
<h3>Botones con color (Otro nivel de información)</h3>
<div class=".fwjs-parrafo">
  <Button icon="fas fa-times" label="Cancelar" class="p-button-cancelar" />
  <Button icon="fas fa-check" label="Aceptar" class="p-button-aceptar" style="margin-left: 20px" />
</div>
<h3>Botones terciarios agrupados</h3>
<div class=".fwjs-parrafo">
  <span class="p-buttonset" style="height: 48px">
    <Button label="Acción 1" icon="fa fa-check" />
    <Button label="Acción 2" icon="pi pi-trash" />
    <Button label="Acción 2" icon="pi pi-trash" />
    <Button label="Acción 2" icon="pi pi-trash" />
    <Button label="Acción 3" icon="fa fa-times" />
  </span>
</div>`,
      codeJs: null,
    };
  },
  components: {
    InputText,
    Button,
    PlantillaShowcase,
  },
};
</script>
