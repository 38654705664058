import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "@/locales";

Vue.config.productionTip = false;

// Primevue
import PrimeVue from "primevue/config";

Vue.use(
  PrimeVue,
  i18n.locale == "es"
    ? {
        locale: {
          accept: "Sí",
          reject: "No",
          choose: "Seleccionar",
          upload: "Subir",
          cancel: "Cancelar",
          dayNames: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
          dayNamesShort: ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"],
          dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
          monthNames: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
          monthNamesShort: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
          today: "Hoy",
          clear: "Borrar",
          weekHeader: "Sem",
          firstDayOfWeek: 1,
          dateFormat: "dd/mm/yy",
          weak: "Débil",
          medium: "Medio",
          strong: "Strong",
          passWordPrompt: "Introduce contraseña",
        },
      }
    : {}
);

// Estilos Primevue
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";

// Font Awesome
import "@fortawesome/fontawesome-free/css/all.css";

import ToastService from "primevue/toastservice";
Vue.use(ToastService);

// Estilo corporativo
import "@mncs/fwjs-components/estiloUMU.css";
const imported = document.createElement("link");
imported.href =
  process.env.NODE_ENV !== "production" ? "https://recursosdesa.um.es/forja/mncs/fontawesome/fa-5-pro/css/all.min.css" : "https://recursos.um.es/forja/mncs/fontawesome/fa-5-pro/css/all.min.css";
imported.rel = "stylesheet";
imported.type = "text/css";
document.head.appendChild(imported);

import mixinFixEstiloUMU from "@mncs/fwjs-components/estiloUMU/mixinFixEstiloUMU.js";
Vue.mixin(mixinFixEstiloUMU);

import mixinMenu from "@/mixins/mixinMenu.js";
Vue.mixin(mixinMenu);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
