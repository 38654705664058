<template>
  <div>
    <PlantillaShowcase header="SelectOne" :codigoHtml="codeHtml" :codigoJs="codeJs">
      <h3>Dropdown</h3>
      <Dropdown v-model="dropdownSeleccionado" :options="opciones" optionLabel="nombre" placeholder="Selecciona una" />
      <Dropdown v-model="dropdownSeleccionado" :options="opciones" optionLabel="nombre" :filter="true" placeholder="Selecciona una" style="margin-left:20px" />
      <h3>InputSwitch</h3>
      <InputSwitch v-model="switchSeleccionado" />
      <InputSwitch v-model="switchSeleccionado" :disabled="true" />
      <h3>RadioButton</h3>
      <div class="p-field-radiobutton">
        <RadioButton id="rb-city1" name="city" value="Chicago" v-model="city" />
        <label for="rb-city1">Chicago</label>
      </div>
      <div class="p-field-radiobutton">
        <RadioButton id="rb-city2" name="city" value="Los Angeles" v-model="city" />
        <label for="rb-city2">Los Angeles</label>
      </div>
      <div class="p-field-radiobutton">
        <RadioButton id="rb-city3" name="city" value="New York" v-model="city" />
        <label for="rb-city3">New York</label>
      </div>
      <div class="p-field-radiobutton">
        <RadioButton id="rb-city4" name="city" value="San Francisco" v-model="city" :disabled="true" />
        <label for="rb-city4">San Francisco</label>
      </div>
      <h4>Fondo oscuro</h4>
      Clase "<strong>radiobutton-dark</strong>"
      <div class="fondo-oscuro">
        <div class="p-field-radiobutton">
          <RadioButton class="radiobutton-dark" id="rbd-city3" name="city" value="New York" v-model="city" />
          <label for="rbd-city3">New York</label>
        </div>
        <div class="p-field-radiobutton">
          <RadioButton class="radiobutton-dark" id="rbd-city4" name="city" value="San Francisco" v-model="city" :disabled="true" />
          <label for="city4">San Francisco</label>
        </div>
      </div>
      <h3>Boolean Checkbox</h3>
      <div class="p-field-checkbox">
        <Checkbox id="cb-city1" name="city" value="Chicago" v-model="cities" />
        <label for="cb-city1">Chicago</label>
      </div>
      <div class="p-field-checkbox">
        <Checkbox id="cb-city2" name="city" value="Los Angeles" v-model="cities" />
        <label for="cb-city2">Los Angeles</label>
      </div>
      <div class="p-field-checkbox">
        <Checkbox id="cb-city3" name="city" value="New York" v-model="cities" />
        <label for="cb-city3">New York</label>
      </div>
      <div class="p-field-checkbox">
        <Checkbox id="cb-city4" name="city" value="San Francisco" v-model="cities" :disabled="true" />
        <label for="cb-city4">San Francisco</label>
      </div>
      <h4>Fondo oscuro</h4>
      Clase "<strong>checkbox-dark</strong>"
      <div class="fondo-oscuro">
        <div class="p-field-checkbox">
          <Checkbox class="checkbox-dark" id="cbd-city3" name="city" value="New York" v-model="cities" />
          <label for="cbd-city3">New York</label>
        </div>
        <div class="p-field-checkbox">
          <Checkbox class="checkbox-dark" id="cbd-city4" name="city" value="San Francisco" v-model="cities" :disabled="true" />
          <label for="cbd-city4">San Francisco</label>
        </div>
      </div>
    </PlantillaShowcase>
  </div>
</template>

<script>
import Dropdown from "primevue/dropdown";
import Checkbox from "primevue/checkbox";
import InputSwitch from "primevue/inputswitch";
import RadioButton from "primevue/radiobutton";
import PlantillaShowcase from "@/components/PlantillaShowcase";

export default {
  data() {
    return {
      codeHtml: `<h3>Dropdown</h3>
<Dropdown v-model="dropdownSeleccionado" :options="opciones" optionLabel="nombre" placeholder="Selecciona una" />
<Dropdown v-model="dropdownSeleccionado" :options="opciones" optionLabel="nombre" :filter="true" placeholder="Selecciona una" style="margin-left:20px" />
<h3>InputSwitch</h3>
<InputSwitch v-model="switchSeleccionado" />
<InputSwitch v-model="switchSeleccionado" :disabled="true" />
<h3>RadioButton</h3>
<div class="p-field-radiobutton">
  <RadioButton id="rb-city1" name="city" value="Chicago" v-model="city" />
  <label for="rb-city1">Chicago</label>
</div>
<div class="p-field-radiobutton">
  <RadioButton id="rb-city2" name="city" value="Los Angeles" v-model="city" />
  <label for="rb-city2">Los Angeles</label>
</div>
<div class="p-field-radiobutton">
  <RadioButton id="rb-city3" name="city" value="New York" v-model="city" />
  <label for="rb-city3">New York</label>
</div>
<div class="p-field-radiobutton">
  <RadioButton id="rb-city4" name="city" value="San Francisco" v-model="city" :disabled="true" />
  <label for="rb-city4">San Francisco</label>
</div>
<h4>Fondo oscuro</h4>
Clase "<strong>radiobutton-dark</strong>"
<div class="fondo-oscuro">
  <div class="p-field-radiobutton">
    <RadioButton class="radiobutton-dark" id="rbd-city3" name="city" value="New York" v-model="city" />
    <label for="rbd-city3">New York</label>
  </div>
  <div class="p-field-radiobutton">
    <RadioButton class="radiobutton-dark" id="rbd-city4" name="city" value="San Francisco" v-model="city" :disabled="true" />
    <label for="city4">San Francisco</label>
  </div>
</div>
<h3>Boolean Checkbox</h3>
<div class="p-field-checkbox">
  <Checkbox id="cb-city1" name="city" value="Chicago" v-model="cities" />
  <label for="cb-city1">Chicago</label>
</div>
<div class="p-field-checkbox">
  <Checkbox id="cb-city2" name="city" value="Los Angeles" v-model="cities" />
  <label for="cb-city2">Los Angeles</label>
</div>
<div class="p-field-checkbox">
  <Checkbox id="cb-city3" name="city" value="New York" v-model="cities" />
  <label for="cb-city3">New York</label>
</div>
<div class="p-field-checkbox">
  <Checkbox id="cb-city4" name="city" value="San Francisco" v-model="cities" :disabled="true" />
  <label for="cb-city4">San Francisco</label>
</div>
<h4>Fondo oscuro</h4>
Clase "<strong>checkbox-dark</strong>"
<div class="fondo-oscuro">
  <div class="p-field-checkbox">
    <Checkbox class="checkbox-dark" id="cbd-city3" name="city" value="New York" v-model="cities" />
    <label for="cbd-city3">New York</label>
  </div>
  <div class="p-field-checkbox">
    <Checkbox class="checkbox-dark" id="cbd-city4" name="city" value="San Francisco" v-model="cities" :disabled="true" />
    <label for="cbd-city4">San Francisco</label>
  </div>
</div>`,
      codeJs: `import Dropdown from "primevue/dropdown";
import Checkbox from "primevue/checkbox";
import InputSwitch from "primevue/inputswitch";
import RadioButton from "primevue/radiobutton";

export default {
  data() {
    return {
      refresh: 0,
      dropdownSeleccionado: null,
      booleanSeleccionado: [],
      switchSeleccionado: false,
      city: null,
      cities: [],
      opciones: [
        { id: 1, nombre: "Cosa 1" },
        { id: 2, nombre: "Cosa 2" },
        { id: 3, nombre: "Cosa 3" },
      ],
      actualizarCheckboxes: 0,
    };
  },
  methods: {
    booleanSelectOne(event) {
      // Para seleccionar sólo uno de los checkboxes
      if (event.length > 1) event.shift(); // shift() elimina el primer elemento de la lista
    },
    actualizar() {
      if (this.refresh == 0) this.refresh++;
      else this.refresh--;
    },
  },
  watch: {
    dropdownSeleccionado: function() {
      this.actualizar();
    },
    switchSeleccionado: function() {
      this.actualizar();
    },
    city: function() {
      this.actualizar();
    },
    cities: function() {
      this.actualizar();
    },
  },
  components: {
    Dropdown,
    Checkbox,
    InputSwitch,
    RadioButton,
  },
};`,
      refresh: 0,
      dropdownSeleccionado: null,
      booleanSeleccionado: [],
      switchSeleccionado: false,
      city: null,
      cities: [],
      opciones: [
        { id: 1, nombre: "Cosa 1" },
        { id: 2, nombre: "Cosa 2" },
        { id: 3, nombre: "Cosa 3" },
      ],
      actualizarCheckboxes: 0,
    };
  },
  methods: {
    booleanSelectOne(event) {
      // Para seleccionar sólo uno de los checkboxes
      if (event.length > 1) event.shift(); // shift() elimina el primer elemento de la lista
    },
    actualizar() {
      if (this.refresh == 0) this.refresh++;
      else this.refresh--;
    },
  },
  watch: {
    dropdownSeleccionado: function() {
      this.actualizar();
    },
    switchSeleccionado: function() {
      this.actualizar();
    },
    city: function() {
      this.actualizar();
    },
    cities: function() {
      this.actualizar();
    },
  },
  components: {
    Dropdown,
    Checkbox,
    PlantillaShowcase,
    InputSwitch,
    RadioButton,
  },
};
</script>

<style scoped>
.fondo-oscuro {
  background-color: var(--gris-oscuro-400);
  padding: 16px;
}
</style>
