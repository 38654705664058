<template>
  <div>
    <h1>Iconos</h1>
    <p class="fwjs-parrafo">En nuestras aplicaciones utilizaremos los iconos de <strong>Font Awesome 5 Pro</strong>. Esto incluye estilos adicionales para los iconos, como los duotone o light.</p>
    <p class="fwjs-parrafo">
      Estos iconos están incluidos en el estilo corporativo, a partir de la versión 0.1.30 del paquete @mncs/fwjs-components. Por lo tanto, debemos actualizarlo si la versión que tenemos en nuestro
      proyecto es anterior.
    </p>
    <h3>Uso de los iconos</h3>
    <p class="fwjs-parrafo">
      A la hora de utilizar los iconos, podemos diferenciar dos casos:
    </p>
    <ul>
      <li>
        <strong>Iconos propios de los componentes</strong>: por ejemplo, hay componentes de Primevue que incluyen iconos propios y no dan la posibilidad de cambiarlo a través de una propiedad. En
        estos casos, <strong>dejaremos sus propios iconos</strong>, no forzaremos el cambio a través de css.
      </li>
      <li>
        <strong>Resto de iconos</strong>: en el resto de casos sí <strong>utilizaremos los iconos de Font Awesome 5</strong>. Podemos ir a la
        <a href="https://fontawesome.com/v5.15/icons?d=gallery&p=2">galería de iconos de Font Awesome 5</a> para ver todos los iconos disponibles y cómo utilizarlos.
        <p class="fwjs-parrafo">
          En la galería, cuando nos metamos en un icono, tendremos una página como la siguiente, donde tenemos a la derecha las diferentes versiones disponibles del icono, y bajo el título tenemos sus
          clases css (en el ejemplo fas fa-arrow-circle-right), que son las que utilizaremos para incluirlo (directamente en una propiedad icon o como clase css en el elemento):
        </p>
        <div style="text-align: center">
          <img src="../assets/images/iconos fa pro.png" alt="Icono Font Awesome 5" style="max-width:80%" />
        </div>
        <p class="fwjs-parrafo">
          Por ejemplo, si queremos utilizar el button de Primevue especificando el icono, lo haremos así:
        </p>
        <Button label="Botón con icono" icon="fal fa-lightbulb" />
        <div class="bloque-codigo">
          <pre class="codigo prettyprint lang-js">&ltButton label="Botón con icono" icon="fal fa-lightbulb" /&gt</pre>
        </div>
        <p class="fwjs-parrafo">
          Y si simplemente queremos poner el icono:
        </p>
        <i class="fal fa-lightbulb" />
        <div class="bloque-codigo">
          <pre class="codigo prettyprint lang-js">&lti class="fal fa-lightbulb" /&gt</pre>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import Button from "primevue/button";
import PlantillaShowcase from "@/components/PlantillaShowcase";

export default {
  data() {
    return {
      codeHtml: ``,
    };
  },
  mounted() {
    // Code blocks syntax highlight
    let scriptCodePrettify = document.createElement("script");
    scriptCodePrettify.setAttribute("src", "https://cdn.rawgit.com/google/code-prettify/master/loader/run_prettify.js");
    document.head.appendChild(scriptCodePrettify);
  },
  components: {
    Button,
    PlantillaShowcase,
  },
};
</script>
