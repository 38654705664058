<template>
  <div>
    <Card>
      <template #title>
        Step 1
      </template>
      <template #content>
        <span>Contenido del step 1</span>
      </template>
    </Card>
    <div style="display: flex; flex-direction: row-reverse; margin-top: 15px">
      <!-- <Button label="Atrás" icon="fas fa-arrow-left" iconPos="left" @click="atras" /> -->
      <Button label="Siguiente" icon="fas fa-arrow-right" iconPos="right" @click="siguiente" />
    </div>
  </div>
</template>

<script>
import Card from "primevue/card";
import Button from "primevue/button";

export default {
  methods: {
    // atras() {
    //   this.$emit("prev-page", { pageIndex: 0 });
    // },
    siguiente() {
      this.$emit("next-page", { pageIndex: 0 });
    },
  },
  components: { Card, Button },
};
</script>

<style></style>
