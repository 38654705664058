<template>
  <div>
    <PlantillaShowcase header="InputGroup" :codigoHtml="codeHtml">
      <h3>InputGroup + Iconos + Texto</h3>
      <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-4">
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">
              <i class="pi pi-user"></i>
            </span>
            <InputText placeholder="Username" />
          </div>
        </div>
        <div class="p-col-12 p-md-4">
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">$</span>
            <InputText placeholder="Price" />
            <span class="p-inputgroup-addon">.00</span>
          </div>
        </div>
      </div>
      <h3>InputGroup múltiples</h3>
      <div class="p-grid">
        <div class="p-col-12 p-md-4">
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">
              <i class="pi pi-clock"></i>
            </span>
            <span class="p-inputgroup-addon">
              <i class="pi pi-star"></i>
            </span>
            <InputText placeholder="Price" />
            <span class="p-inputgroup-addon">$</span>
            <span class="p-inputgroup-addon">.00</span>
          </div>
        </div>
      </div>
    </PlantillaShowcase>
  </div>
</template>

<script>
import InputText from "primevue/inputtext";
import PlantillaShowcase from "@/components/PlantillaShowcase";

export default {
  data() {
    return {
      codeHtml: `<h3>InputGroup + Iconos + Texto</h3>
<div class="p-grid p-fluid">
  <div class="p-col-12 p-md-4">
    <div class="p-inputgroup">
      <span class="p-inputgroup-addon">
        <i class="pi pi-user"></i>
      </span>
      <InputText placeholder="Username" />
    </div>
  </div>
  <div class="p-col-12 p-md-4">
    <div class="p-inputgroup">
      <span class="p-inputgroup-addon">$</span>
      <InputText placeholder="Price" />
      <span class="p-inputgroup-addon">.00</span>
    </div>
  </div>
</div>
<h3>InputGroup múltiples</h3>
<div class="p-grid">
  <div class="p-col-12 p-md-4">
    <div class="p-inputgroup">
      <span class="p-inputgroup-addon">
        <i class="pi pi-clock"></i>
      </span>
      <span class="p-inputgroup-addon">
        <i class="pi pi-star"></i>
      </span>
      <InputText placeholder="Price" />
      <span class="p-inputgroup-addon">$</span>
      <span class="p-inputgroup-addon">.00</span>
    </div>
  </div>
</div>`,
      codeJs: null,
      }
  },
  components: {
    InputText,
    PlantillaShowcase,
  },
};
</script>
