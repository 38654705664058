import Vue from 'vue'
import Vuex from 'vuex'
// import loadingGlobal from "./modules/loadingGlobal"
import loadingGlobal from "@mncs/fwjs-components/loader/loadingGlobal"
 
Vue.use(Vuex)
 
export default new Vuex.Store({
  state: {
 
  },
  mutations: {
 
  },
  actions: {
     
  },
  getters: {
     
  },
  modules: {
    loadingGlobal
  }
})