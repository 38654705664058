<template>
  <div>
    <PlantillaShowcase header="Panel" :codigoHtml="codeHtml">
      <div class="p-grid">
        <div class="p-col-12 p-md-4">
          <Panel header="No toggleable">
            Esto es un panel no toggleable
          </Panel>
        </div>
        <div class="p-col-12 p-md-4">
          <Panel header="Toggleable" :toggleable="true">
            Esto es un panel sí toggleable, dale a la rayita para... togglearlo?
          </Panel>
        </div>
      </div>
    </PlantillaShowcase>
  </div>
</template>

<script>
import Panel from "primevue/panel";
import PlantillaShowcase from "@/components/PlantillaShowcase";

export default {
  data() {
    return {
      codeHtml: `<div class="p-grid">
  <div class="p-col-12 p-md-4">
    <Panel header="No toggleable">
      Esto es un panel no toggleable
    </Panel>
  </div>
  <div class="p-col-12 p-md-4">
    <Panel header="Toggleable" :toggleable="true">
      Esto es un panel sí toggleable, dale a la rayita para... togglearlo?
    </Panel>
  </div>
</div>`,
      codeJs: null,
    };
  },
  components: {
    Panel,
    PlantillaShowcase,
  },
};
</script>
