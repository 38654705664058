<template>
  <div>
    <h1>Espaciado</h1>
      <p class="fwjs-parrafo">Aunque el diseño de los espacios queda a decisión de la persona que haga la página y en función de las necesidades de esta, tenemos unos espacios predeterminados en el estilo corporativo.</p>
      <p class="fwjs-parrafo">Tenemos algunas clases css hechas para estos espacios predefinidos:</p>
      <ul>
        <li>Espacio entre párrafos - 16px. Clase "<strong>fwjs-parrafo</strong>".</li>
        <li>Espacio entre secciones (ej. bloque en un formulario) - 36px. Clase "<strong>fwjs-seccion</strong>".</li>
        <li>Títulos - Tamaño de fuente * 1,5. Clases "<strong>fwjs-h1</strong>", "<strong>fwjs-h2</strong>", "<strong>fwjs-h3</strong>", "<strong>fwjs-h4</strong>", "<strong>fwjs-h5</strong>".</li>
      </ul>
      <p class="fwjs-parrafo">Además, con <strong>Primeflex</strong> también disponemos de una serie de clases con múltiples opciones para el espaciado de nuestras aplicaciones. Podemos consultar la <a href="https://www.primefaces.org/primevue/showcase-v2/#/spacing">documentación oficial</a>.</p>

  </div>
</template>

<script>
import PlantillaShowcase from "@/components/PlantillaShowcase";

export default {
  data() {
    return {
      codeHtml: ``,
    };
  },
  components: {
    PlantillaShowcase,
  },
};
</script>
