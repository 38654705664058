<template>
  <div>
    <h1>Header</h1>
    <p class="fwjs-parrafo">
      En nuestras aplicaciones FundeWebJS utilizaremos la cabecera estandarizada que tenemos en el repositorio Verdaccio. Para instalarla, debemos tener el paquete
      <strog>@mncs/fwjs-components</strog> en la versión <strong>0.1.43</strong> o superior.
    </p>
    <p class="fwjs-parrafo">
      Además, si nuestra aplicación va a hacer login con el Portal de Servicios, será necesario tener el store de Vuex con la misma estructura que se muestra en <a href="">la documentación</a> (se
      utiliza el módulo "user"). Esto se utiliza para mostrar la foto e información del usuario logeado.
    </p>
    <h3>Props que recibe el componente:</h3>
    <ul>
      <li><strong>title</strong>: <i>String</i>. Nombre de la aplicación. <strong>Obligatorio</strong>.</li>
      <li><strong>subtitle</strong>: <i>String</i>. Descripción de la aplicación. Opcional.</li>
      <li><strong>isLogged</strong>: <i>Booleano</i>. Para mostrar enlace de acceso o no. Por defecto a <strong>false</strong>.</li>
      <li><strong>hasSearch</strong>: <i>Booleano</i>. Nombre de la aplicación. Por defecto a <strong>false</strong>.</li>
      <li><strong>hasWidget</strong>: <i>Booleano</i>. Nombre de la aplicación. Por defecto a <strong>true</strong>.</li>
      <li><strong>hasBack</strong>: <i>Booleano</i>. Nombre de la aplicación. Por defecto a <strong>false</strong>.</li>
    </ul>
    <h3>Eventos que emite el componente:</h3>
    <ul>
      <li><strong>@hamburgerClick</strong>: Cuando hasBack es false y se pulsa sobre el botón de la izquierda.</li>
      <li><strong>@loginClick</strong>: Cuando se pulsa sobre el enlace de acceso en la parte derecha de la cabecera.</li>
      <li><strong>@searchChange</strong>: Cuando se pulsa enter en el input de la búsqueda.</li>
      <li><strong>@searchKeyUp</strong>: Cuando el input de la búsqueda se modifica.</li>
    </ul>
    <h3>Slot</h3>
    <p class="fwjs-parrafo">
      El componente proporciona un slot, es decir, podemos poner contenido para mostrar al clickar en la imagen del usuario. Por ejemplo:
    </p>
    <div class="bloque-codigo">
      <pre class="codigo prettyprint lang-js">
&ltfwjs-header
  :title="appName"
  :has-back="true"
  :is-logged="isLogged"
  @backClick="campus"
  @loginClick="login"
>
  &lth1>Vuestro contenido&lt/h1>
  &ltp>
    El contenido específico de la aplicación.
    Adaptará su altura a móvil también.
  &lt/p>
&lt/fwjs-header>
</pre
      >
    </div>
    <p class="fwjs-parrafo">
      Sin utilizar el slot se vería así:
    </p>
    <div style="text-align: center">
      <img src="../assets/images/sinslot.png" alt="Icono Font Awesome 5" style="max-width:80%" />
    </div>
    <p class="fwjs-parrafo">
      Y utilizando el slot:
    </p>
    <div style="text-align: center">
      <img src="../assets/images/conslot.png" alt="Icono Font Awesome 5" style="max-width:80%" />
    </div>
  </div>
</template>

<script>
import Button from "primevue/button";
import PlantillaShowcase from "@/components/PlantillaShowcase";

export default {
  data() {
    return {
      codeHtml: ``,
    };
  },
  mounted() {
    // Code blocks syntax highlight
    let scriptCodePrettify = document.createElement("script");
    scriptCodePrettify.setAttribute("src", "https://cdn.rawgit.com/google/code-prettify/master/loader/run_prettify.js");
    document.head.appendChild(scriptCodePrettify);
  },
  components: {
    Button,
    PlantillaShowcase,
  },
};
</script>
