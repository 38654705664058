<template>
  <div>
    <PlantillaShowcase header="Fieldset" :codigoHtml="codeHtml">
      <Fieldset style="width: 50%; margin-bottom: 30px">
        <template #legend>Asignatura</template>
        <label for="it0">Label</label>
        <InputText id="it0" type="text" placeholder="Text" style="width: 100%; margin-bottom: 10px" />
        <label for="it00">Label</label>
        <InputText id="it00" type="text" placeholder="Text" style="width: 100%" />
      </Fieldset>
      <Fieldset style="width: 50%; margin-bottom: 30px">
        <label for="it0">Label</label>
        <InputText id="it0" type="text" placeholder="Text" style="width: 100%; margin-bottom: 10px" />
        <label for="it00">Label</label>
        <InputText id="it00" type="text" placeholder="Text" style="width: 100%" />
      </Fieldset>
      <Fieldset legend="Fieldset 1" style="width: 50%; margin-bottom: 30px">
        <label for="it1">Label</label>
        <InputText id="it1" type="text" placeholder="Text" style="width: 100%; margin-bottom: 10px" />
        <label for="it11">Label</label>
        <InputText id="it11" type="text" placeholder="Text" style="width: 100%" />
      </Fieldset>
      <Fieldset legend="Fieldset 2" :toggleable="true" style="width: 50%">
        <label for="it2">Label</label>
        <InputText id="it2" type="text" placeholder="Text" style="width: 100%" />
      </Fieldset>
    </PlantillaShowcase>
  </div>
</template>

<script>
import Fieldset from "primevue/fieldset";
import InputText from "primevue/inputtext";
import PlantillaShowcase from "@/components/PlantillaShowcase";

export default {
  data() {
    return {
      codeHtml: `<Fieldset style="width: 50%; margin-bottom: 30px">
  <label for="it0">Label</label>
  <InputText id="it0" type="text" placeholder="Text" style="width: 100%; margin-bottom: 10px" />
  <label for="it00">Label</label>
  <InputText id="it00" type="text" placeholder="Text" style="width: 100%" />
</Fieldset>
<Fieldset legend="Fieldset 1" style="width: 50%; margin-bottom: 30px">
  <label for="it1">Label</label>
  <InputText id="it1" type="text" placeholder="Text" style="width: 100%; margin-bottom: 10px" />
  <label for="it11">Label</label>
  <InputText id="it11" type="text" placeholder="Text" style="width: 100%" />
</Fieldset>
<Fieldset legend="Fieldset 2" :toggleable="true" style="width: 50%">
  <label for="it2">Label</label>
  <InputText id="it2" type="text" placeholder="Text" style="width: 100%" />
</Fieldset>`,
      codeJs: null,
    };
  },
  components: {
    Fieldset,
    InputText,
    PlantillaShowcase,
  },
};
</script>
