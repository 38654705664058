<template>
  <div>
    <PlantillaShowcase header="Pagination" :codigoHtml="codeHtml">
      <Paginator
        :rows="5"
        :totalRecords="1234"
        :rowsPerPageOptions="[2, 5, 7, 10, 500]"
        template="CurrentPageReport PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
        currentPageReportTemplate="{first} - {last} de {totalRecords}"
      ></Paginator>
    </PlantillaShowcase>
  </div>
</template>

<script>
import Paginator from "primevue/paginator";
import PlantillaShowcase from "@/components/PlantillaShowcase";

export default {
  data() {
    return {
      codeHtml: `<Paginator
  :rows="5"
  :totalRecords="1234"
  :rowsPerPageOptions="[2, 5, 7, 10, 500]"
  template="CurrentPageReport PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
  currentPageReportTemplate="{first} - {last} de {totalRecords}"
></Paginator>`,
      codeJs: null,
    };
  },
  components: {
    Paginator,
    PlantillaShowcase,
  },
};
</script>
