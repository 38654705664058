<template>
  <div>
    <PlantillaShowcase header="Steps" :codigoHtml="codeHtml" :codigoJs="codeJs">
      <Steps :model="items" />
      <keep-alive>
        <router-view @prev-page="prevPage($event)" @next-page="nextPage($event)" style="margin-top: 20px" />
      </keep-alive>
      <div style="margin-top: 25px">
        <Card>
          <template #title>
            Instrucciones
          </template>
          <template #content>
            En los componentes de cada step tendremos que emitir los eventos "prevPage" y "nextPage" movernos entre los diferentes pasos. El manejo de estos eventos se hace en la vista general, la del
            Steps, y lo podemos ver en el código de más abajo.<br /><br />Además, las rutas de cada step tendrán que ser hijas de la ruta padre, la que contiene el Steps, por ejemplo:
            
    <div class="bloque-codigo">
            <pre class="codigo prettyprint lang-js">
{
  path: "/steps",
  name: "Steps",
  component: StepsView,
  children: [
    { path: "", component: Step1 },
    { path: "/steps/step2", component: Step2 },
    { path: "/steps/step3", component: Step3 },
    { path: "/steps/step4", component: Step4 },
    { path: "/steps/step5", component: Step5 },
  ],
},</pre
            >
    </div>
          </template>
        </Card>
      </div>
    </PlantillaShowcase>
  </div>
</template>

<script>
import Steps from "primevue/steps";
import Card from "primevue/card";
import PlantillaShowcase from "@/components/PlantillaShowcase";

export default {
  mounted() {
    // Code blocks syntax highlight
    let scriptCodePrettify = document.createElement("script");
    scriptCodePrettify.setAttribute("src", "https://cdn.rawgit.com/google/code-prettify/master/loader/run_prettify.js");
    document.head.appendChild(scriptCodePrettify);
  },
  data() {
    return {
      items: [
        { label: "Step 1", to: "/steps" },
        { label: "Step 2", to: "/steps/step2" },
        { label: "Step 3", to: "/steps/step3" },
        { label: "Step 4", to: "/steps/step4" },
        { label: "Step 5", to: "/steps/step5" },
      ],
      codeHtml: `<Steps :model="items" />
<keep-alive>
  <router-view @prev-page="prevPage($event)" @next-page="nextPage($event)" style="margin-top: 20px"/>
</keep-alive>`,
      codeJs: `import Steps from "primevue/steps";

export default {
  data() {
    return {
      items: [
        { label: "Step 1", to: "/steps" },
        { label: "Step 2", to: "/steps/step2" },
        { label: "Step 3", to: "/steps/step3" },
        { label: "Step 4", to: "/steps/step4" },
        { label: "Step 5", to: "/steps/step5" },
      ],
    };
  },
  methods: {
      nextPage(event) {
        this.$router.push(this.items[event.pageIndex + 1].to);
      },
      prevPage(event) {
        this.$router.push(this.items[event.pageIndex - 1].to);
      },
  },
  components: {
    Steps,
  },
};`,
    };
  },
  methods: {
    nextPage(event) {
      this.$router.push(this.items[event.pageIndex + 1].to);
    },
    prevPage(event) {
      this.$router.push(this.items[event.pageIndex - 1].to);
    },
  },
  components: {
    Card,
    Steps,
    PlantillaShowcase,
  },
};
</script>


<style scoped>
.bloque-codigo {
  background: #ffffff;
  overflow: auto;
  width: auto;
  border: solid #bec7cf;
  border-width: 0.1em 0.1em 0.1em 0.8em;
  padding: 0.2em 0.6em;
  margin: 0.75rem 0;
}

.codigo {
  border: none !important;
}
</style>