<template>
  <div>
    <PlantillaShowcase header="Autocomplete" :codigoHtml="codeHtml" :codigoJs="codeJs">
      Para el calendario y date picker no utilizamos los componentes de Primevue, utilizamos <a href="https://vcalendar.io/datepicker.html">V-Calendar</a>, pues es más completo y se ajusta a las funcionalidades que necesitamos. A continuación tenemos ejemplos de date picker, que podemos ver cómo importarlo y utilizarlos en el código de debajo.
      <h3>Un día</h3>
      <DatePicker v-model="date" :attributes="attrs" />
      <h3>Fecha y hora</h3>
      <DatePicker mode="dateTime" v-model="date" :attributes="attrs" />
      <DatePicker mode="dateTime" v-model="date" :attributes="attrs" style="margin-left: 10px" is24hr />
      <h3>Rango</h3>
      <DatePicker v-model="date" :attributes="attrs" is-range />
      <DatePicker mode="dateTime" v-model="date" :attributes="attrs" style="margin-left: 10px" is-range is24hr />
      <h3>Emergente</h3>
      <DatePicker v-model="date" :attributes="attrs">
        <template v-slot="{ inputValue, inputEvents }">
          <InputText class="bg-white border px-2 py-1 rounded" :value="inputValue" v-on="inputEvents" />
        </template>
      </DatePicker>
      <div style="margin-top: 10px">
        <DatePicker v-model="date" :attributes="attrs" is-range>
          <template v-slot="{ inputValue, inputEvents }">
            <div class="flex justify-center items-center">
              <InputText :value="inputValue.start" v-on="inputEvents.start" class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300" />
              -
              <InputText :value="inputValue.end" v-on="inputEvents.end" class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300" />
            </div>
          </template>
        </DatePicker>
      </div>
    </PlantillaShowcase>
  </div>
</template>

<script>
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import InputText from "primevue/inputtext";
import PlantillaShowcase from "@/components/PlantillaShowcase";

export default {
  data() {
    return {
      codeHtml: `<h3>Un día</h3>
<DatePicker v-model="date" :attributes="attrs" />
<h3>Fecha y hora</h3>
<DatePicker mode="dateTime" v-model="date" :attributes="attrs" />
<DatePicker mode="dateTime" v-model="date" :attributes="attrs" style="margin-left: 10px" is24hr />
<h3>Rango</h3>
<DatePicker v-model="date" :attributes="attrs" is-range />
<DatePicker mode="dateTime" v-model="date" :attributes="attrs" style="margin-left: 10px" is-range is24hr />
<h3>Emergente</h3>
<DatePicker v-model="date" :attributes="attrs">
  <template v-slot="{ inputValue, inputEvents }">
    <InputText class="bg-white border px-2 py-1 rounded" :value="inputValue" v-on="inputEvents" />
  </template>
</DatePicker>
<div style="margin-top: 10px">
  <DatePicker v-model="date" :attributes="attrs" is-range>
    <template v-slot="{ inputValue, inputEvents }">
      <div class="flex justify-center items-center">
        <InputText :value="inputValue.start" v-on="inputEvents.start" class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300" />
        -
        <InputText :value="inputValue.end" v-on="inputEvents.end" class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300" />
      </div>
    </template>
  </DatePicker>
</div>`,
      codeJs: `import DatePicker from "v-calendar/lib/components/date-picker.umd";
import InputText from "primevue/inputtext";

export default {
  data() {
    return {
      date: null,
      range: null,
      attrs: [
        // Hoy
        {
          key: "today",
          highlight: {
            class: "circle-today",
            contentClass: "date-today",
          },
          dates: new Date(),
        },
      ],
    };
  },
  components: {
    DatePicker,
    InputText,
  },
};`,
      date: null,
      range: null,
      attrs: [
        // Hoy
        {
          key: "today",
          highlight: {
            class: "circle-today",
            contentClass: "date-today",
          },
          dates: new Date(),
        },
      ],
    };
  },
  components: {
    DatePicker,
    InputText,
    PlantillaShowcase,
  },
};
</script>
