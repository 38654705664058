<template>
  <div>
    <PlantillaShowcase header="Datatable tamaños" :codigoHtml="codeHtml" :codigoJs="codeJs">
      <h3>Normal</h3>
      <DataTable
        class="p-datatable-striped"
        :value="gente"
        :paginator="true"
        paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
        currentPageReportTemplate="{first} - {last} de {totalRecords}"
        :rows="5"
        :rowsPerPageOptions="[2, 5, 7]"
        dataKey="nombre"
        :rowHover="true"
        selectionMode="single"
        :selection.sync="genteSeleccionada"
        :filters="filtros"
        :loading="loading"
      >
        <template #header>
          <div class="table-header">
            Cabecera Tabla (Header)
          </div>
        </template>
        <Column field="nombre" header="Nombre" :sortable="true"></Column>
        <Column field="edad" header="Edad" :sortable="true"></Column>
        <Column field="idioma" header="Idioma" :sortable="true"></Column>
      </DataTable>
      <h3>Small</h3>
      <span>Clase "<strong>datatable-sm</strong>"</span>
      <DataTable
        class="p-datatable-striped datatable-sm"
        :value="gente"
        :paginator="true"
        paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
        currentPageReportTemplate="{first} - {last} de {totalRecords}"
        :rows="5"
        :rowsPerPageOptions="[2, 5, 7]"
        dataKey="nombre"
        :rowHover="true"
        selectionMode="single"
        :selection.sync="genteSeleccionada"
        :filters="filtros"
        :loading="loading"
      >
        <template #header>
          <div class="table-header">
            Cabecera Tabla (Header)
          </div>
        </template>
        <Column field="nombre" header="Nombre" :sortable="true"></Column>
        <Column field="edad" header="Edad" :sortable="true"></Column>
        <Column field="idioma" header="Idioma" :sortable="true"></Column>
      </DataTable>
      <h3>Large</h3>
      <span>Clase "<strong>datatable-lg</strong>"</span>
      <DataTable
        class="p-datatable-striped datatable-lg"
        :value="gente"
        :paginator="true"
        paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
        currentPageReportTemplate="{first} - {last} de {totalRecords}"
        :rows="5"
        :rowsPerPageOptions="[2, 5, 7]"
        dataKey="nombre"
        :rowHover="true"
        selectionMode="single"
        :selection.sync="genteSeleccionada"
        :filters="filtros"
        :loading="loading"
      >
        <template #header>
          <div class="table-header">
            Cabecera Tabla (Header)
          </div>
        </template>
        <Column field="nombre" header="Nombre" :sortable="true"></Column>
        <Column field="edad" header="Edad" :sortable="true"></Column>
        <Column field="idioma" header="Idioma" :sortable="true"></Column>
      </DataTable>
    </PlantillaShowcase>
  </div>
</template>

<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import PlantillaShowcase from "@/components/PlantillaShowcase";

export default {
  data() {
    return {
      genteSeleccionada: null,
      filtros: {},
      loading: false,
      gente: [
        { nombre: "Guille", edad: 26, idioma: "Español" },
        { nombre: "Gulielmus", edad: 11, idioma: "Latín" },
        { nombre: "Guglielmo", edad: 100, idioma: "Italiano" },
        { nombre: "Guillaume", edad: 45, idioma: "Francés" },
        { nombre: "Guilhem", edad: 65, idioma: "Occitano" },
        { nombre: "Guillem", edad: 44, idioma: "Catalán" },
        { nombre: "Guilherme", edad: 31, idioma: "Portugués" },
        { nombre: "Guillerme", edad: 97, idioma: "Gallego" },
        { nombre: "Gillen", edad: 55, idioma: "Euskera" },
        { nombre: "威廉", edad: 3, idioma: "Chino" },
        { nombre: "ウィリアム", edad: 33, idioma: "Japonés" },
        { nombre: "Γουλιέλμος ", edad: -3, idioma: "Griego" },
        { nombre: "Guildhelm ", edad: 72, idioma: "Flamenco" },
        { nombre: "Вильгельм", edad: 17, idioma: "Ruso" },
        { nombre: "William", edad: 28, idioma: "Inglés" },
        { nombre: "Acho", edad: 0, idioma: "Murciano" },
        { nombre: "Fuiiiii", edad: 88, idioma: "El de La Gomera" },
        { nombre: "Grrhrhrhaahahrhra", edad: 142, idioma: "Wookie" },
        { nombre: "01000111", edad: 11010, idioma: "Binario" },
      ],
      codeHtml: `<h3>Normal</h3>
<DataTable
  class="p-datatable-striped"
  :value="gente"
  :paginator="true"
  paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
  currentPageReportTemplate="{first} - {last} de {totalRecords}"
  :rows="5"
  :rowsPerPageOptions="[2, 5, 7]"
  dataKey="nombre"
  :rowHover="true"
  selectionMode="single"
  :selection.sync="genteSeleccionada"
  :filters="filtros"
  :loading="loading"
>
  <template #header>
    <div class="table-header">
      Cabecera Tabla (Header)
    </div>
  </template>
  <Column field="nombre" header="Nombre" :sortable="true"></Column>
  <Column field="edad" header="Edad" :sortable="true"></Column>
  <Column field="idioma" header="Idioma" :sortable="true"></Column>
</DataTable>
<h3>Small</h3>
<span>Clase "<strong>datatable-sm</strong>"</span>
<DataTable
  class="p-datatable-striped datatable-sm"
  :value="gente"
  :paginator="true"
  paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
  currentPageReportTemplate="{first} - {last} de {totalRecords}"
  :rows="5"
  :rowsPerPageOptions="[2, 5, 7]"
  dataKey="nombre"
  :rowHover="true"
  selectionMode="single"
  :selection.sync="genteSeleccionada"
  :filters="filtros"
  :loading="loading"
>
  <template #header>
    <div class="table-header">
      Cabecera Tabla (Header)
    </div>
  </template>
  <Column field="nombre" header="Nombre" :sortable="true"></Column>
  <Column field="edad" header="Edad" :sortable="true"></Column>
  <Column field="idioma" header="Idioma" :sortable="true"></Column>
</DataTable>
<h3>Large</h3>
<span>Clase "<strong>datatable-lg</strong>"</span>
<DataTable
  class="p-datatable-striped datatable-lg"
  :value="gente"
  :paginator="true"
  paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
  currentPageReportTemplate="{first} - {last} de {totalRecords}"
  :rows="5"
  :rowsPerPageOptions="[2, 5, 7]"
  dataKey="nombre"
  :rowHover="true"
  selectionMode="single"
  :selection.sync="genteSeleccionada"
  :filters="filtros"
  :loading="loading"
>
  <template #header>
    <div class="table-header">
      Cabecera Tabla (Header)
    </div>
  </template>
  <Column field="nombre" header="Nombre" :sortable="true"></Column>
  <Column field="edad" header="Edad" :sortable="true"></Column>
  <Column field="idioma" header="Idioma" :sortable="true"></Column>
</DataTable>`,
      codeJs: `import DataTable from "primevue/datatable";
import Column from "primevue/column";

export default {
  data() {
    return {
      genteSeleccionada: null,
      filtros: {},
      loading: false,
      gente: [
        { nombre: "Guille", edad: 26, idioma: "Español" },
        { nombre: "Gulielmus", edad: 11, idioma: "Latín" },
        { nombre: "Guglielmo", edad: 100, idioma: "Italiano" },
        { nombre: "Guillaume", edad: 45, idioma: "Francés" },
        { nombre: "Guilhem", edad: 65, idioma: "Occitano" },
        { nombre: "Guillem", edad: 44, idioma: "Catalán" },
        { nombre: "Guilherme", edad: 31, idioma: "Portugués" },
        { nombre: "Guillerme", edad: 97, idioma: "Gallego" },
        { nombre: "Gillen", edad: 55, idioma: "Euskera" },
        { nombre: "威廉", edad: 3, idioma: "Chino" },
        { nombre: "ウィリアム", edad: 33, idioma: "Japonés" },
        { nombre: "Γουλιέλμος ", edad: -3, idioma: "Griego" },
        { nombre: "Guildhelm ", edad: 72, idioma: "Flamenco" },
        { nombre: "Вильгельм", edad: 17, idioma: "Ruso" },
        { nombre: "William", edad: 28, idioma: "Inglés" },
        { nombre: "Acho", edad: 0, idioma: "Murciano" },
        { nombre: "Fuiiiii", edad: 88, idioma: "El de La Gomera" },
        { nombre: "Grrhrhrhaahahrhra", edad: 142, idioma: "Wookie" },
        { nombre: "01000111", edad: 11010, idioma: "Binario" },
      ],      
    };
  },
  components: {
    DataTable,
    Column,
    PlantillaShowcase,
  },
};`,
    };
  },
  components: {
    DataTable,
    Column,
    PlantillaShowcase,
  },
};
</script>
