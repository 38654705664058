import Vue from "vue"; 
import VueI18n from "vue-i18n"; 
Vue.use(VueI18n); 
 
// Importamos locales 
import es from "./es.js"; 
import en from "./en.js"; 
 
export const messages = { es, en }; 
 
export default new VueI18n({ 
locale: process.env.VUE_APP_I18N_LOCALE || "es", 
fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "es", 
silentTranslationWarn: true, 
messages, 
}); 
