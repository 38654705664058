<template>
  <div>
    <PlantillaShowcase header="Datatable" :codigoHtml="codeHtml" :codigoJs="codeJs">
      <DataTable
        responsiveLayout="stack"
        class="p-datatable-striped"
        :value="gente"
        :paginator="true"
        paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
        currentPageReportTemplate="{first} - {last} de {totalRecords}"
        :rows="7"
        :rowsPerPageOptions="[2, 5, 7]"
        dataKey="nombre"
        :rowHover="true"
        selectionMode="single"
        :selection.sync="genteSeleccionada"
        :filters="filtros"
        :loading="loading"
        :expandedRows.sync="expandedRows"
      >
        <template #header>
          <div class="table-header">
            Cabecera Tabla (Header)
          </div>
        </template>
        <Column :expander="true" headerStyle="width: 3rem" />
        <Column field="nombre" header="Nombre" :sortable="true"></Column>
        <Column field="edad" header="Edad" :sortable="true"></Column>
        <Column field="idioma" header="Idioma" :sortable="true"></Column>
        <template #expansion="slotProps">
          <div>Esto es el contenido expandible de la fila {{ slotProps.data.nombre }}</div></template
        >
      </DataTable>

      <h3>Responsive</h3>
      <p class="fwjs-parrafo">
        Los datatables, por lo general, <b><u>no son una buena opción</u></b
        >, pues suelen dar problemas a la hora de visualizarlos en móvil. Si decidimos utilizarlos, en la <b>versión 2.8.0 de Primevue</b> han incluido dos opciones para hacer las tablas responsive.
        Se trata de la propiedad <b>responsiveLayout</b>, y puede tomar los valores <b>"scroll"</b> y <b>"stack"</b>.
      </p>
      <p class="fwjs-parrafo">
        El valor por defecto de esta propiedad es <b>stack</b>, que apila las columnas. Con la opción de <b>scroll</b>, se mantiene la tabla igual, pero permite hacer scroll. Esto lo hace para
        anchuras de pantalla de hasta 960px.
      </p>
      <p class="fwjs-parrafo">
        Si no utilizamos esto, tendremos que personalizar la tabla añadiendo estilos css. Por ejemplo (recalco que es solo un ejemplo, cada uno si quiere tendrá que personalizarlo según las
        necesidades de su aplicación), podríamos hacer algo así, añadiendo una clase "datatable-responsive" (clase del elemento DataTable), otra "filtro-responsive" para los filtros de las columnas ,
        una clase "body-column" para el cuerpo de las columnas (propiedad bodyClass de Column), la clase "column-title" para el título de las columnas (utilizada dentro del template #body de Column),
        y definiendo sus css:
      </p>
      <div class="bloque-codigo">
        <pre class="codigo prettyprint lang-js">
&ltstyle lang="scss" scoped>
@media screen and (min-width: 960px) {
  .filtro-responsive {
    display: none;
  }

  ::v-deep .body-column {
    text-align: center !important;
  }
}

.datatable-responsive .p-datatable-tbody > tr > td .column-title {
  display: none;
}

@media screen and (max-width: 960px) {

  ::v-deep .p-datatable-header {
    display: none;
  }

  ::v-deep .p-datatable {
    &.datatable-responsive {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;
      }

      .p-datatable-tbody {
        border-top: 1px solid var(--surface-d);
      }

      .p-datatable-thead {
        display: none;
      }

      .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        width: 100%;
        float: left;
        clear: left;
        border: 0 none;
        height: revert;

        .column-title {
          padding: 0.4rem;
          display: inline-block;
          margin: 0 0 0 -0.4rem;
          font-weight: bold;
        }

        &:last-child {
          border-bottom: 1px solid var(--surface-d);
        }
      }
    }
  }
}
&lt/style>          
        </pre>
      </div>
    </PlantillaShowcase>
  </div>
</template>

<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import PlantillaShowcase from "@/components/PlantillaShowcase";

export default {
  data() {
    return {
      genteSeleccionada: null,
      filtros: {},
      loading: false,
      gente: [
        { nombre: "Guille", edad: 26, idioma: "Español" },
        { nombre: "Gulielmus", edad: 11, idioma: "Latín" },
        { nombre: "Guglielmo", edad: 100, idioma: "Italiano" },
        { nombre: "Guillaume", edad: 45, idioma: "Francés" },
        { nombre: "Guilhem", edad: 65, idioma: "Occitano" },
        { nombre: "Guillem", edad: 44, idioma: "Catalán" },
        { nombre: "Guilherme", edad: 31, idioma: "Portugués" },
        { nombre: "Guillerme", edad: 97, idioma: "Gallego" },
        { nombre: "Gillen", edad: 55, idioma: "Euskera" },
        { nombre: "威廉", edad: 3, idioma: "Chino" },
        { nombre: "ウィリアム", edad: 33, idioma: "Japonés" },
        { nombre: "Γουλιέλμος ", edad: -3, idioma: "Griego" },
        { nombre: "Guildhelm ", edad: 72, idioma: "Flamenco" },
        { nombre: "Вильгельм", edad: 17, idioma: "Ruso" },
        { nombre: "William", edad: 28, idioma: "Inglés" },
        { nombre: "Acho", edad: 0, idioma: "Murciano" },
        { nombre: "Fuiiiii", edad: 88, idioma: "El de La Gomera" },
        { nombre: "Grrhrhrhaahahrhra", edad: 142, idioma: "Wookie" },
        { nombre: "01000111", edad: 11010, idioma: "Binario" },
      ],
      expandedRows: [],
      codeHtml: `<DataTable
  class="p-datatable-striped"
  :value="gente"
  :paginator="true"
  paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
  currentPageReportTemplate="{first} - {last} de {totalRecords}"
  :rows="7"
  :rowsPerPageOptions="[2, 5, 7]"
  dataKey="nombre"
  :rowHover="true"
  selectionMode="single"
  :selection.sync="genteSeleccionada"
  :filters="filtros"
  :loading="loading"
  :expandedRows.sync="expandedRows"
>
  <template #header>
    <div class="table-header">
      Cabecera Tabla (Header)
    </div>
  </template>
  <Column :expander="true" headerStyle="width: 3rem" />
  <Column field="nombre" header="Nombre" :sortable="true"></Column>
  <Column field="edad" header="Edad" :sortable="true"></Column>
  <Column field="idioma" header="Idioma" :sortable="true"></Column>
  <template #expansion="slotProps"> Esto es el contenido expandible de la fila {{ slotProps.data.nombre }} </template>
</DataTable>`,
      codeJs: `import DataTable from "primevue/datatable";
import Column from "primevue/column";

export default {
  data() {
    return {
      genteSeleccionada: null,
      filtros: {},
      loading: false,
      gente: [
        { nombre: "Guille", edad: 26, idioma: "Español" },
        { nombre: "Gulielmus", edad: 11, idioma: "Latín" },
        { nombre: "Guglielmo", edad: 100, idioma: "Italiano" },
        { nombre: "Guillaume", edad: 45, idioma: "Francés" },
        { nombre: "Guilhem", edad: 65, idioma: "Occitano" },
        { nombre: "Guillem", edad: 44, idioma: "Catalán" },
        { nombre: "Guilherme", edad: 31, idioma: "Portugués" },
        { nombre: "Guillerme", edad: 97, idioma: "Gallego" },
        { nombre: "Gillen", edad: 55, idioma: "Euskera" },
        { nombre: "威廉", edad: 3, idioma: "Chino" },
        { nombre: "ウィリアム", edad: 33, idioma: "Japonés" },
        { nombre: "Γουλιέλμος ", edad: -3, idioma: "Griego" },
        { nombre: "Guildhelm ", edad: 72, idioma: "Flamenco" },
        { nombre: "Вильгельм", edad: 17, idioma: "Ruso" },
        { nombre: "William", edad: 28, idioma: "Inglés" },
        { nombre: "Acho", edad: 0, idioma: "Murciano" },
        { nombre: "Fuiiiii", edad: 88, idioma: "El de La Gomera" },
        { nombre: "Grrhrhrhaahahrhra", edad: 142, idioma: "Wookie" },
        { nombre: "01000111", edad: 11010, idioma: "Binario" },
      ],
      expandedRows: [],
      };
  },
  components: {
    DataTable,
    Column,
  },
};`,
    };
  },

  mounted() {
    // Code blocks syntax highlight
    let scriptCodePrettify = document.createElement("script");
    scriptCodePrettify.setAttribute("src", "https://cdn.rawgit.com/google/code-prettify/master/loader/run_prettify.js");
    document.head.appendChild(scriptCodePrettify);
  },
  components: {
    DataTable,
    Column,
    PlantillaShowcase,
  },
};
</script>

<style lang="scss" scoped>
// #filtro-curso {
//   max-width: 150px;
//   min-width: 110px;
//   width: 100%;
// }

// @media screen and (min-width: 768px) {
//   .filtro-responsive {
//     display: none;
//   }

//   ::v-deep .body-big {
//     text-align: center !important;
//   }
// }

// .datatable-responsive .p-datatable-tbody > tr > td .column-title {
//   display: none;
// }

// // @media screen and (max-width: 40em) {
// @media screen and (max-width: 768px) {
//   .boton-pdf {
//     margin: 0.2em;
//   }

//   ::v-deep .p-datatable-header {
//     display: none;
//   }

//   ::v-deep .p-datatable {
//     &.datatable-responsive {
//       .p-datatable-thead > tr > th,
//       .p-datatable-tfoot > tr > td {
//         display: none !important;
//       }

//       .p-datatable-tbody {
//         border-top: 1px solid var(--surface-d);
//       }

//       .p-datatable-thead {
//         display: none;
//       }

//       .p-datatable-tbody > tr > td {
//         text-align: left;
//         display: block;
//         width: 100%;
//         float: left;
//         clear: left;
//         border: 0 none;
//         height: revert;

//         .column-title {
//           padding: 0.4rem;
//           // min-width: 30%;
//           display: inline-block;
//           margin: 0 0 0 -0.4rem;
//           font-weight: bold;
//         }

//         &:last-child {
//           border-bottom: 1px solid var(--surface-d);
//         }
//       }
//     }
//   }
// }
</style>
