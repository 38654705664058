<template>
  <div>
    <PlantillaShowcase header="Enlaces" :codigoHtml="codeHtml">
      <h3>Enlace primario</h3>
      Clase "<strong>enlace-primario</strong>": <a class="enlace-primario" href="#">Enlace primario</a>
      <h3>Enlace primario destacado</h3>
      Clase "<strong>enlace-primario-destacado</strong>": <a class="enlace-primario-destacado" href="#">Enlace primario</a>
      <h3>Enlace secundario</h3>
      Clase "<strong>enlace-secundario</strong>": <a class="enlace-secundario" href="#">Enlace secundario</a>
      <h3>Enlace clásico - Casos especiales</h3>
      Clase "<strong>enlace-clasico</strong>": <a class="enlace-clasico" href="#">Enlace secundario</a>
    </PlantillaShowcase>
  </div>
</template>

<script>
import PlantillaShowcase from "@/components/PlantillaShowcase";

export default {
  data() {
    return {
      codeHtml: `<h3>Enlace primario</h3>
Clase "<strong>enlace-primario</strong>": <a class="enlace-primario" href="#">Enlace primario</a>
<h3>Enlace primario destacado</h3>
Clase "<strong>enlace-primario-destacado</strong>": <a class="enlace-primario-destacado" href="#">Enlace primario</a>
<h3>Enlace secundario</h3>
Clase "<strong>enlace-secundario</strong>": <a class="enlace-secundario" href="#">Enlace secundario</a>
<h3>Enlace clásico - Casos especiales</h3>
Clase "<strong>enlace-clasico</strong>": <a class="enlace-clasico" href="#">Enlace secundario</a>`,
    };
  },
  components: {
    PlantillaShowcase,
  },
};
</script>
