<template>
  <div>
    <PlantillaShowcase header="Calendario" :codigoHtml="codeHtml" :codigoJs="codeJs">
      Para el calendario y date picker no utilizamos los componentes de Primevue, utilizamos <a class="enlace-primario-destacado" href="https://vcalendar.io/attributes.html">V-Calendar</a>, pues es más completo y se ajusta a las funcionalidades que necesitamos. A continuación tenemos un ejemplo del componente, que podemos ver cómo importarlo y utilizarlo en el código de debajo.
      <div class="p-grid" style="margin-top: 20px">
        <div class="p-col-12 p-md-4">
          <Calendar :attributes="attrs" :min-date="new Date()" @dayclick="seleccionarDia" />
        </div>
        <div class="p-col-12 p-md-8" v-if="diaSeleccionado">
          <h4>{{ diaSeleccionado.dia }}</h4>
          <div v-for="evento in diaSeleccionado.eventos" :key="evento.key">
            <h5 style="margin-bottom: 0.5em">{{ evento.label }}</h5>
            <div v-for="(value, key) in evento.customData" :key="key">
              <strong>{{ key }}:</strong> {{ value }}
            </div>
          </div>
        </div>
      </div>
    </PlantillaShowcase>
  </div>
</template>

<script>
import Calendar from "v-calendar/lib/components/calendar.umd";
import PlantillaShowcase from "@/components/PlantillaShowcase";

export default {
  data() {
    const date = new Date();
    return {
      codeHtml: `<div class="p-grid" style="margin-top: 20px">
  <div class="p-col-12 p-md-4">
    <Calendar :attributes="attrs" :min-date="new Date()" @dayclick="seleccionarDia" />
  </div>
  <div class="p-col-12 p-md-8" v-if="diaSeleccionado">
    <h4>{{ diaSeleccionado.dia }}</h4>
    <div v-for="evento in diaSeleccionado.eventos" :key="evento.key">
      <h5 style="margin-bottom: 0.5em">{{ evento.label }}</h5>
      <div v-for="(value, key) in evento.customData" :key="key">
        <strong>{{ key }}:</strong> {{ value }}
      </div>
    </div>
  </div>
</div>`,
      codeJs: `import Calendar from "v-calendar/lib/components/calendar.umd";

export default {
  data() {
    const date = new Date();
    return {
      diaSeleccionado: null,
      attrs: [
        // Hoy
        {
          key: "today",
          highlight: {
            class: "circle-today",
            contentClass: "date-today",
          },
          dates: new Date(),
        },
        // Día con evento
        {
          key: "event",
          dates: new Date(year, month, 22),
          dot: {
            class: "dot-event",
          },
          popover: {
            label: "Título del evento",
            customData: {
              Descripción: "Tremendo evento, no te lo pierdas.",
              Lugar: "CSU",
              Precio: "0€",
            },
          },
        },
      ],
    };
  },
  methods: {
    seleccionarDia(e) {
      this.diaSeleccionado = { dia: e.ariaLabel, eventos: e.popovers };
    },
  },
  components: {
    Calendar,
  },
};`,
      diaSeleccionado: null,
      attrs: [
        // Hoy
        // {
        //   key: "today",
        //   highlight: {
        //     class: "circle-today",
        //     contentClass: "date-today",
        //   },
        //   dates: new Date(),
        // },
        // Día con evento
        {
          key: "event",
          dates: new Date(date.getFullYear(), date.getMonth(), 22),
          dot: {
            class: "dot-event",
          },
          popover: {
            label: "Título del evento",
            customData: {
              Descripción: "Tremendo evento, no te lo pierdas.",
              Lugar: "CSU",
              Precio: "0€",
            },
          },
        },
      ],
    };
  },
  methods: {
    seleccionarDia(e) {
      this.diaSeleccionado = { dia: e.ariaLabel, eventos: e.popovers };
    },
  },
  components: {
    Calendar,
    PlantillaShowcase,
  },
};
</script>
